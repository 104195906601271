@font-face {
    font-family: 'Mercury';
    src: url('../fonts/Mercury-Regular.eot?62418065');
    src: url('../fonts/Mercury-Regular.eot?62418065#iefix') format('embedded-opentype'),
    url('../fonts/Mercury-Regular.woff?62418065') format('woff'),
    url('../fonts/Mercury-Regular.ttf?62418065') format('truetype'),
    url('../fonts/Mercury-Regular.svg?62418065#mercury') format('svg');
    font-weight: 400;
    font-style: normal;
}

[class^="mercury-icon-"]:before, [class*="mercury-icon-"]:before,
.mercury-ico{
    font-family: 'Mercury';
    font-weight: 400;
    font-style: normal;
    font-size: inherit;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.mercury-icon-time:before {
    content: '\e800'
}

.mercury-icon-angle-up:before {
    content: '\e801'
}

.mercury-icon-angle-left:before {
    content: '\e802'
}

.mercury-icon-angle-bottom:before {
    content: '\e803'
}

.mercury-icon-angle-right:before {
    content: '\e804'
}

.mercury-icon-bars:before {
    content: '\e805'
}

.mercury-icon-satellite:before {
    content: '\e806'
}

.mercury-icon-group:before {
    content: '\e807'
}

.mercury-icon-social:before {
    content: '\e808'
}

.mercury-icon-pin:before {
    content: '\e809'
}

.mercury-icon-target:before {
    content: '\e80a'
}

.mercury-icon-chart-up:before {
    content: '\e80b'
}

.mercury-icon-key:before {
    content: '\e80c'
}

.mercury-icon-search:before {
    content: '\e80d'
}

.mercury-icon-card:before {
    content: '\e80e'
}

.mercury-icon-gears:before {
    content: '\e80f'
}

.mercury-icon-touch:before {
    content: '\e810'
}

.mercury-icon-partners:before {
    content: '\e811'
}

.mercury-icon-money:before {
    content: '\e812'
}

.mercury-icon-chart:before {
    content: '\e813'
}

.mercury-icon-note:before {
    content: '\e814'
}

.mercury-icon-books:before {
    content: '\e815'
}

.mercury-icon-pie-chart:before {
    content: '\e816'
}

.mercury-icon-phone:before {
    content: '\e817'
}

.mercury-icon-phone-24:before {
    content: '\e818'
}

.mercury-icon-pencil:before {
    content: '\e819'
}

.mercury-icon-mobile:before {
    content: '\e81a'
}

.mercury-icon-presentation:before {
    content: '\e81b'
}

.mercury-icon-note-2:before {
    content: '\e81c'
}

.mercury-icon-time-back:before {
    content: '\e81d'
}

.mercury-icon-presentation-2:before {
    content: '\e81e'
}

.mercury-icon-tools:before {
    content: '\e81f'
}

.mercury-icon-news:before {
    content: '\e820'
}

.mercury-icon-cup:before {
    content: '\e821'
}

.mercury-icon-search-font:before {
    content: '\e822'
}

.mercury-icon-clock:before {
    content: '\e823'
}

.mercury-icon-users:before {
    content: '\e824'
}

.mercury-icon-user:before {
    content: '\e825'
}

.mercury-icon-chart-seacrh:before {
    content: '\e826'
}

.mercury-icon-screen:before {
    content: '\e827'
}

.mercury-icon-lightbulb:before {
    content: '\e828'
}

.mercury-icon-tag:before {
    content: '\e829'
}

.mercury-icon-chat:before {
    content: '\e82a'
}

.mercury-icon-window:before {
    content: '\e82b'
}

.mercury-icon-tablet:before {
    content: '\e82c'
}

.mercury-icon-lib:before {
    content: '\e82d'
}

.mercury-icon-wallet:before {
    content: '\e82e'
}

.mercury-icon-pointer:before {
    content: '\e82f'
}

.mercury-icon-speak:before {
    content: '\e830'
}

.mercury-icon-globe:before {
    content: '\e831'
}

.mercury-icon-calc:before {
    content: '\e832'
}

.mercury-icon-desktop:before {
    content: '\e833'
}

.mercury-icon-pointer-left:before {
    content: '\e834'
}

.mercury-icon-chart-up-2:before {
    content: '\e835'
}

.mercury-icon-scales:before {
    content: '\e836'
}

.mercury-icon-cloud:before {
    content: '\e837'
}

.mercury-icon-desktop-chart:before {
    content: '\e838'
}

.mercury-icon-calendar:before {
    content: '\e839'
}

.mercury-icon-e-mail-o:before {
    content: '\e83a'
}

.mercury-icon-gear:before {
    content: '\e83b'
}

.mercury-icon-lightbulb-gears:before {
    content: '\e83c'
}

.mercury-icon-presentation-3:before {
    content: '\e83d'
}

.mercury-icon-money-2:before {
    content: '\e83e'
}

.mercury-icon-print:before {
    content: '\e83f'
}

.mercury-icon-time-sand:before {
    content: '\e840'
}

.mercury-icon-e-mail:before {
    content: '\e841'
}

.mercury-icon-paper:before {
    content: '\e842'
}

.mercury-icon-lock:before {
    content: '\e843'
}

.mercury-icon-case:before {
    content: '\e844'
}

.mercury-icon-money-3:before {
    content: '\e845'
}

.mercury-icon-jobs:before {
    content: '\e846'
}

.mercury-icon-document-search:before {
    content: '\e847'
}

.mercury-icon-globe-marker:before {
    content: '\e848'
}

.mercury-icon-folder:before {
    content: '\e849'
}

.mercury-icon-briefcase:before {
    content: '\e84a'
}

.mercury-icon-target-2:before {
    content: '\e84b'
}

.mercury-icon-cloud-2:before {
    content: '\e84c'
}

.mercury-icon-house:before {
    content: '\e84d'
}
