/*
*
* Breadcrumbs
* --------------------------------------------------
*/

//== Breadcrumbs variables
//
//##

$bread-color: $primary;
$bread-active-color: $white;

* + .section-breadcrumb{
  margin-top: 0;

  @media (min-width: 1200px) {
    border-top: 1px solid  rgba($white, .2);
    margin-top: 35px;
  }
}

.section-breadcrumb{
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: $gray-darker;

  h3{
    position: relative;
    letter-spacing: .025em;

    @media (min-width: 768px) {
      padding-left: 20px;
    }

    &:before{
      left: calc(50% - 12px);
      width: 24px;
      height: 2px;
      bottom: -18px;

      content: '';
      position: absolute;
      background-color: $primary;

      @media (min-width: 768px) {
        top: 4px;
        bottom: 11px;
        height: auto;
        left: 0;
        width: 3px;
      }
    }
  }
}

.breadcrumb-custom{
  will-change: transform;
  margin-top: 20px;
  list-style: none;
  letter-spacing: .1em;

  > li {
    display: inline-block;
    color: $bread-active-color;

    > a{
      color: $bread-color;

      &:hover{
        text-decoration: underline;
      }
    }

    &:last-child{
      &:after{
        content: none;
      }
    }

    &:after {
      padding: 0 10px 0 12px;
      content: "/";
      font-size: 18px;
      color: rgba($white, .2);
      vertical-align: middle;
    }
  }
}