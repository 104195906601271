/*
*
* Sections
* --------------------------------------------------
*/


// Section Spacing
// -------------------------

.section-sm {
  padding-top: 75px;
  padding-bottom: 75px;
}

.section-md {
  padding-top: 65px;
  padding-bottom: 65px;

  @media (min-width: 992px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.section-lg {
  padding-top: 125px;
  padding-bottom: 125px;
}

.section-bottom-0{
  padding-bottom: 0;
}

// Section Styles
// -------------------------


.grid-element {
  padding: 12px 5px; }
@media (min-width: 768px) {
  .grid-element {
    padding: 15px 10px; } }
@media (min-width: 992px) {
  .grid-element {
    padding: 25px 15px; } }
@media (min-width: 1200px) {
  .grid-element {
    padding: 45px 38px 45px 38px; } }
@media (max-width: 1199px) {
  .grid-element p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; } }

.grid-element-mod-2 {
  padding: 12px 0; }
@media (min-width: 768px) {
  .grid-element-mod-2 {
    padding: 15px 0; } }
@media (min-width: 992px) {
  .grid-element-mod-2 {
    padding: 25px 0; } }
@media (min-width: 1200px) {
  .grid-element-mod-2 {
    padding: 45px 0; } }
@media (max-width: 1199px) {
  .grid-element-mod-2 p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; } }

.row.row-no-gutter > [class*='col'] {
  margin-top: 0;
  padding-left: 0;
  padding-right: 0; }

.grid-element-outline {
  position: relative; }
.grid-element-outline:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  left: -1px;
  border: 1px solid #eee;
  border-right: 0; }

.grid-element-outline-bottom {
  position: relative; }
.grid-element-outline-bottom:before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  border-top: 1px solid #eee; }

@media (max-width: 767px) {
  .grid-system-row [class*='col'] {
    padding-left: 8px;
    padding-right: 8px; } }


.section-typography-body{
  padding-bottom: 90px;
}

.section-stretch{
  display: flex;
  align-items: center;
  min-height: calc(100vh - 269px);
  @media (min-height: 890px) {
    height:calc(100vh - 340px);
  }

 

  > *{
    display: inline-block;
    vertical-align: middle;
  }
}

.section-relative {
  position: relative;
  z-index: 1;
}

// Section Grid Demonstration
.section-grid-demonstration {
  @media (max-width: $screen-xs-min) {
    [class^="col"] {
      padding: 5px;
    }
  }
  .grid-demonstration-item {
    padding: 8px 14px;
    background: #f8f8f8;

    @media (min-width: $screen-sm-min) {
      padding: 35px 30px;
      text-align: left;
    }

    @media (max-width: $screen-md-max) {
      h3 {
        font-size: 16px;
        line-height: 16px;
      }
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
    }
  }

  .divider + .row {
    margin-top: 35px;
  }
}