/*
*
* Buttons
* --------------------------------------------------
*/


// Button General Styles

.button {
  display: inline-block;
  position: relative;
  // btn-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius)
  @include btn-size(13px, 35px, 14px, 20px, 4px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-style: solid;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  user-select: none;
  transition: 250ms all ease-in-out;
  font-family: $font-family-sec;
  text-transform: uppercase;
  letter-spacing: .05em;
  font-weight: 600;

  &:focus,
  &:active,
  &:active:focus {
    outline: none;
  }

  &.disabled{
    color: black;
    background-color: gray;
  }

  .caret{
    margin-left: 8px;
  }
}

.button-block{
  display: block;
  width: 100%;
}

.button-lined{
  border: 2px solid $gray-darker;
  border-bottom: none;
  padding: 12px 20px;
  color: #151515;
  overflow: visible;

  border-radius: 0;
  z-index: 1;

  transition: 1s;

  &:after{
    content: '';
    position: absolute;
    bottom: 0;
    width: 44%;
    height: 2px;
    background-color: #2f353f;
    left: 50%;
    transform: translateX(-50%);
    transition: .2s ease-in .6s;
  }

  &:before{
    content: '';
    position: absolute;
    bottom: 0;
    left: -5px;
    right: -5px;
    top: -5px;
    background-color: white;
    transition: bottom .2s ease-in .4s, left .4s ease-in .0s, right .4s ease-in .0s;
    z-index: -1;
  }

  &:hover{
    color: $gray-darker;

    &:before {
      transition: bottom .2s ease-in .2s, left .2s ease-in .4s, right .2s ease-in .4s;
      bottom: 100%;
      left: 50%;
      right: 50%;
    }

    &:after{
      transition: .2s ease-in .0s;
      background-color: $gray-darker;
      width: 100%;
    }
  }
}

// Appearance style
// --------------------------------------------------

.button-default {
  @include btn-variant($white, $gray-darker, $gray-darker, $gray-darker, transparent, $gray-darker);
  border-width: 1px;
}

.button-wars {
  @include btn-variant($white, #2f353f, #2f353f, $white, $secondary, $secondary);
}

.button-default-outline {
  @include btn-variant($gray-base, transparent, $gray-lighter, $white, $gray-darker, $gray-darker);
}

.button-white-outline {
  border-width: 2px;
  @include btn-variant($white, transparent, $white, $gray-base, $white, $white);
}

.button-default-outline-2 {
  border-width: 1px;
  @include btn-variant($gray-base, transparent, $gray-base, $white, $gray-darker, $gray-darker);
}

.button-primary {
  @include btn-variant($gray-base, $primary, $primary, $white, $gray-darker, $gray-darker);
}

.button-steel-blue {
  @include btn-variant($white, #547ABB, #547ABB, $white, darken(#547ABB, 10%), darken(#547ABB, 10%));
}

.button-cerulean {
  @include btn-variant($white, #00bbf2, #00bbf2, $white, darken(#00bbf2, 5%), darken(#00bbf2, 5%));
}

.button-mandy {
  @include btn-variant($white, #e75854, #e75854, $white, darken(#e75854, 10%), darken(#e75854, 10%));
}

// Btn with shadow
.button-shadow{
  box-shadow: 0 9px 21px 0 rgba(30, 30, 30, 0.05);

  &:hover{
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
  }
}


// Button Sizes
// --------------------------------------------------

.button-xs {
  // btn-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius)
  @include btn-size(5px, 26px, 12px, 24px, 4px);
}

.button-sm {
  // btn-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius)
  @include btn-size(5px, 26px, 12px, 24px, 4px);
  letter-spacing: .12em;
}

.button-lg {
  // btn-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius)
  @include btn-size(16px, 63px, 16px, 22px, 4px);
  letter-spacing: .05em;
}

.button-xl {
  // btn-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius)
  @include btn-size(24px, 78px, 18px, 22px, 4px);
  letter-spacing: 0;
}


// Button Shapes
// --------------------------------------------------

.button-circle {
  border-radius: 30px;
}

.button-square {
  border-radius: 0;
}

.button-ellipse {
  &-md {
    border-radius: 5px;
  }

  &-lg {
    border-radius: 10px;
  }
}


// Button Icon styles
// --------------------------------------------------

.button {
  &.button-icon {

    .icon {
      font-size: 18px;
      line-height: inherit;
      vertical-align: middle;
      transition: 0s;
    }

    &-left {
      .icon {
        float: left;
        padding-right: 10px;
      }
    }

    &-right {
      .icon {
        float: right;
        padding-left: 10px;
      }
    }
  }
}