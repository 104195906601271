/*
*
* Comment
* --------------------------------------------------
*/

.comment{

  &__author{
    letter-spacing: .05em;
    font-weight: 500;
  }

  &__date{
    font-size: 14px;
    letter-spacing: 0;
  }

  &__footer{
    margin-top: 24px;

    > *{
      & + *{
        margin-left: 30px;
      }
    }
  }

  &__like,
  &__reply{
    letter-spacing: .1em;
    font-weight: 500;
    color: $gray-base;

    &:before{
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
      color: #9fa3a7;
      font-size: 16px;
      font-family: "Material Design Icons";
    }
  }

  &__like{
    &:before{
      content: '\f602';
    }
  }

  &__reply{
    &:before{
      content: '\f278';
    }
  }

  .comment.comment-reply{
    padding-left: 36px;
    margin-top: 20px;
  }
}

.comment-top-panel{
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + p{
    margin-top: 8px;
  }
}

.comment + .comment{
  margin-top: 42px;
  padding-top: 40px;
  border-top: 1px solid #e1e1e1;
}

.comment-box{
  padding-top: 40px;
  margin-top: 30px;
  border-top: 1px solid #e1e1e1;

  .unit__body{
    width: 100%;
  }

  .form-validation{
    bottom: auto;
    top: 3px;
  }

  textarea{
    margin-top: 20px;
    padding: 20px 25px;
    border: 1px solid #ebebeb;
    height: 130px;
    color: #9b9b9b;


    &::-webkit-input-placeholder {
      color: #9b9b9b;
    }

    &:-moz-placeholder {
      color: #9b9b9b;
    }

    &::-moz-placeholder {
      color: #9b9b9b;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #9b9b9b;
    }
  }
}