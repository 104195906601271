/*
*
* Swiper
* --------------------------------------------------
*/


.swiper-container {
  height: 100vh;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  /* Fix of Webkit flickering */
  z-index: 0;
}

.swiper-videos{
  position: static;
  overflow: visible;
  height: auto;

  .swiper-slide{
    .thumbnail-type-4{
      pointer-events: none;
    }

    &.swiper-slide-active{
      .thumbnail-type-4 {
        pointer-events: all;
      }
    }
  }

  .swiper-pagination{
    position: static;
  }
}

.swiper-slide{
  .thumbnail-type-4{
    width: 100%;
    max-width: none;
  }
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  @include flex-direction(column);
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  @include display-flex();
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
}

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  touch-action: pan-y;
}

.swiper-wp8-vertical {
  touch-action: pan-x;
}


/* Coverflow */
.swiper-container-coverflow .swiper-wrapper {
  /* Windows 8 IE 10 fix */
}


// Swiper fade effect
//--------------------------------------------------

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}


// Swiper preloader
//--------------------------------------------------

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  transform-origin: 50%;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}

.swiper-slide > .vide__body,
.swiper-slide > .parallax_cnt {
  height: 100%;
}


// Swiper navigation
//--------------------------------------------------

.swiper-button-prev,
.swiper-button-next {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;

  position: absolute;
  top: 54%;
  transform: translateY(-50%);
  font-size: 28px;
  font-family: "Material Design Icons";
  color: $primary;
  background-color: rgba(#2f353f, .51);
  z-index: 1;
  cursor: pointer;

  display: none;

  @media (min-width: $screen-md-min) {
    display: block;
  }

  &:hover {
    color: $white;
  }

  // Disabled button
  &.swiper-button-disabled{
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
}

.swiper-button-prev {
  left: 0;

  &:before{
    content: "\f237";
  }
}

.swiper-button-next {
  right: 0;

  &:before{
    content: "\f238";
  }
}


.swiper-slider.swiper-container-rtl{
  .swiper-button-prev {
    &:before{
      content: "\f061";
    }
  }

  .swiper-button-next {
    &:before{
      content: "\f060";
    }
  }
}



// Swiper pagination
//--------------------------------------------------

.swiper-pagination {
  margin-top: 20px;
  position: absolute;
  text-align: center;
  transition: .3s;
  transform: translate3d(0, 0, 0);
  //z-index: 10;
  display: block;

  @media (min-width: $screen-md-min) {
    display: none;
  }

  &.swiper-pagination-hidden {
    opacity: 0;
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 100%;
    background: rgba(255,255,255,.6);
    transition: .2s;

    &.swiper-pagination-bullet-active,
    &:hover{
      background: rgba(255,255,255,1);
    }
  }

  &.swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
  }
}

// Dark pagination
.swiper-pagination-black{
  .swiper-pagination-bullet {
    background: rgba(0,0,0,.6);

    &.swiper-pagination-bullet-active {
      background: rgba(0,0,0,1);
    }
  }
}


// Swiper pagination orientation
//--------------------------------------------------

// Vertical pagination
.swiper-container-vertical{
  > .swiper-pagination {
    right: 10px;
    top: 50%;
    transform: translate3d(0px, -50%, 0);

    .swiper-pagination-bullet {
      margin: 5px 0;
      display: block;
    }
  }
}


// Horizontal pagination
.swiper-container-horizontal{
  > .swiper-pagination {
    bottom: 20px;
    left: 0;
    width: 100%;

    .swiper-pagination-bullet {
      margin: 0 5px;
    }
  }
}


// Swiper slide styles
//--------------------------------------------------

.swiper-wrapper{
  .swiper-slide{
    &:nth-child(1){
      .swiper-slide-cover{
        transform-origin: 50% 0;
      }
    }

    &:nth-child(2){
      .swiper-slide-cover{
        transform: scale(1);
        transform-origin: 100% 100%;
      }

      &.swiper-slide-active{
        .swiper-slide-cover{
          transform: scale(1.07);
        }
      }
    }

    &:nth-child(3){
      .swiper-slide-cover{
        transform-origin: 0% 0%;
      }
    }
  }
}

.swiper-slide {
  position: relative;

  @include display-flex();
  @include align-items(center);
  @include justify-content(center);
  @include flex-shrink(0);
  width: 100%;
  height: 100%;

  white-space: nowrap;

  @extend %context-dark;

  &-cover{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    transition: 7s ease-out;
    transform: scale(1.07);
  }

  &.swiper-slide-active{
    .swiper-slide-cover{
      transform: scale(1);
    }
  }
}