/*
*
* Quote
* --------------------------------------------------
*/

.quote{
  position: relative;
  padding-top: 40px;
  font-family: $font-family-sec;
  font-size: 16px;

  @media (min-width: 992px) {
    font-size: 18px;
    padding-top: 8px;
    padding-left: 130px;
    padding-right: 14%;
  }

  &:before{
    @media (min-width: 992px) {
      font-size: 175px;
      left: -7px;
      transform: none;
    }

    display: block;
    position: absolute;
    top: 0;
    font-size: 80px;
    content: '“';
    font-family: 'Roboto';
    line-height: 1;
    font-weight: 700;
    color: #e5e7f0;
  }

  &:after{
    @media (min-width: 992px) {
      content: '';
    }
    background-color: #dddddd;
    position: absolute;
    left: 92px;
    top: 3px;
    height: 64px;
    width: 1px;
  }

  q{
    letter-spacing: 0;

    &:before,
    &:after{
      content: none;
    }
  }

  cite{
    font-style: normal;
    font-weight: 600;
    letter-spacing: .02em;
  }
}

.quote__wrapper{
  position: relative;
  padding-top: 28px;
  padding-bottom: 28px;

  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}

.quote--variant-1{

  .quote{
    padding-top: 0;
    padding-left: 62px;
    padding-right: 20px;

    &:before{
      left: 6px;
      top: -1px;
      font-size: 106px;
    }

    &:after{
      content: none;
    }
  }

  q{
    font-size: 18px;
    letter-spacing: .025em;
    line-height: 30px;
    font-weight: 600;
  }
}

.page .quote--variant-2 {
  position: relative;
  background-color: #2f353f;
  color: $white;
  text-align: left;
  margin-bottom: 15px;
  padding: 9%;

  @media (min-width: $screen-md-min) {
    padding: 15% 7% 13% 9%;
  }

  &:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 70px;
    display: block;
    transform: translateY(100%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 25px 15px 0;
    border-color: transparent #2f353f transparent transparent;
  }

  .quote{
    padding: 0;
    font-size: 14px;
    font-family: $font-family-base;

    @media (min-width: $screen-md-min) {
      padding-left: 40px;
    }

    @media (min-width: 1400px) {
      padding-left: 90px;
    }

    &:before{
      color: #596372;
      font-size: 0;

      @media (min-width: $screen-md-min) {
        font-size: 70px;
      }

      @media (min-width: 1400px) {
        top: -10px;
        font-size: 175px;
      }
    }

    h4{
      letter-spacing: .025em;
    }

    .unit{
      margin-top: 20px;

      p{
        letter-spacing: 0;
        color: $primary;
        margin: 0;
      }
    }

    .quote__date{
      text-transform: uppercase;
      color: #596372;
      font-size: 12px;
      letter-spacing: .12em;
      font-family: $font-family-sec;

      & + p{
        margin-top: 9px;
      }
    }

    &:after{
      content: none;
    }
  }

  #{headings()}{
    color: $white;
  }

}