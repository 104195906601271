/*
*
* Pagination custom
* --------------------------------------------------
*/

//== Pagination variables
//

$pagination-padding-vertical: 10px;
$pagination-padding-horizontal: 10px;

$pagination-font-size: 16px;
$pagination-lh-size: 20px;
$pagination-border-w: 0;

$pagination-color:                     $gray-base;
$pagination-bg:                        transparent;
$pagination-border:                    #ddd;

$pagination-hover-color:               $primary;
$pagination-hover-bg:                  $gray-darker;
$pagination-hover-border:              $gray-darker;

$pagination-active-color:              $primary;
$pagination-active-bg:                 $gray-darker;
$pagination-active-border:             $gray-darker;

$pagination-disabled-color:            $gray-light;
$pagination-disabled-bg:               #fff;
$pagination-disabled-border:           #ddd;


.pagination-custom {
  font-family: $font-family-sec;
  font-size: 0;

  > li {
    margin: 0 6px;

    &,
    > a,
    > span{
      display: inline-block;
    }

    > a,
    > span {
      width: 28px;
      height: 28px;
      line-height: 28px;
      border-radius: 50%;
      font-size: $pagination-font-size;
    }
  }

  > li > a,
  > li > span {
    font-weight: 500;
    transition: .3s;
    text-align: center;
    background-color: transparent;
    color: $pagination-color;
  }

  > li:first-child,
  > li:last-child {
    a, span {
      span {
        position: relative;
        font-size: 11px;
        line-height: 1;
      }
    }
  }

  > li > a,
  > li > span {
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: $pagination-hover-color;
      background-color: $pagination-hover-bg;
      border-color: $pagination-hover-border;
      box-shadow: none;
    }
  }

  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: $pagination-active-color;
      background-color: $pagination-active-bg;
      border-color: $pagination-active-border;
      box-shadow: none;
    }
  }
}

