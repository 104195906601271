/*
*
* Vide
* --------------------------------------------------
*/


.vide{
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
  > *{
    background-color: #212121;
    
    video{
      opacity: .6;
    }
  }
}