/*
*
* Tabs
* --------------------------------------------------
*/


//== Tabs variables
//
//##

// Base
$tabs-color: #888a8c;
$tabs-background: transparent;
$tabs-font: 400 14px/24px $font-family-sec;
$tabs-padding: 4px 8px;

// Horizontal tabs
$tabs-horizontal-active-color: $secondary;
$tabs-horizontal-active-background: transparent;
$tabs-horizontal-background: transparent;

// Vertical tabs
$tabs-vertical-active-color: $secondary;
$tabs-vertical-active-line: $secondary;



// Base styles
//--------------------------------------

.tabs-custom {
  .nav-custom {
    &> li, &> li > a {
      display: block;
      position: relative;
    }
  }

  .nav-custom-tabs {
    font-size: 0;
    line-height: 0;

    li {
      margin: -1px 0;

      &+ li {
        @media (min-width: $screen-sm-min) {
          margin-top: 0;
          margin-left: 9px;
        }
      }
    }
  }
}

.tabs-custom {
  .nav-custom-tabs {
    li a {
      position: relative;
      padding: $tabs-padding;
      font: $tabs-font;
      color: $tabs-color;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      vertical-align: middle;
      transition: .3s all ease;
    }

    li a:hover,
    li.active a {
      color: $secondary;
    }
  }
}


.tabs-flat{

  & + .tab-content{
    background-color: #2b3439;
    margin-top: 0;
    padding: 5.5%;

    .tab-pane{
      display: block;
      visibility: hidden;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      &:nth-child(2){
        @media (min-width: 1400px) {
          padding-top: 30px;
          padding-bottom: 30px;
        }
      }

      &.active{
        visibility: visible;
        position: static;
      }
    }
  }
}

.nav-flat{
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: .05em;

  display: flex;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-x: auto;

  @media (min-width: $screen-md-min) {
    overflow-x: visible;
  }

  li{
    flex-basis: 16%;
    display: inline-block;

    a{
      width: 100%;
      display: inline-block;
      color: #4f585d;
      padding: 12px 24px;

      @media (min-width: $screen-md-min) {
        padding: 15px 10px;
      }
    }

    &.active{
      position: relative;

      &:after{
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 7px solid rgba(0, 0, 0, 0.29);
        filter: blur(7px);
        z-index: -1;
      }

      a{
        background-color: #2b3439;
        color: #fff;
      }
    }
  }
}

.tab-content {
  color: #888a8c;
  padding-top: 20px;

  @media (min-width: $screen-sm-min) {
    padding-top: 32px;
  }
}


// Horizontal tabs
//--------------------------------------

.tabs-custom {
  &.tabs-horizontal {
    .nav-custom-tabs {
      li + li {
        border-top: 1px solid #e1e1e1;
      }
    }

    @media (min-width: $screen-sm-min) {
      .nav-custom-tabs {
        position: relative;
        @include justify-content(center);
        @include display-flex;

        li{
          padding-left: 22px;
          padding-right: 22px;

          & + li{
            border-top: none;
            border-left: 1px solid #e1e1e1;
          }
        }

        li a {
          padding: 0;
          line-height: 16px;
          display: block;
          background: $tabs-horizontal-background;
        }

        li a:hover,
        li.active a {
          color: $tabs-horizontal-active-color;
          background: $tabs-horizontal-active-background;

          &:before {
            transform: translate(-50%, 0);
            opacity: 1;
          }
        }
      }
    }
  }
}


// Vertical tabs
//--------------------------------------

.tabs-custom {
  &.tabs-vertical {
    .nav-custom-tabs{
      li + li {
        border-top: 1px solid #e1e1e1;
      }
    }

    @media (min-width: $screen-sm-min) {
      @include display-flex;
      @include flex-direction(row);
      @include flex-wrap(nowrap);
      @include align-items(stretch);

      .nav-custom-tabs {
        @include display-flex;
        @include flex-direction(column);
        @include flex-wrap(nowrap);
        @include align-items(stretch);
        @include flex-shrink(0);
        max-width: 50%;
      }

      .tab-content {
        @include flex-grow(1);
      }

      .nav-custom-tabs {
        text-align: left;

        li{
          padding-left: 4px;

          & + li {
            margin-top: 8px;
            padding-top: 8px;
            margin-left: 0;

            border-top: 1px solid #e1e1e1;
          }
        }

        li a {
          display: inline-block;
          padding: 5px 28px 5px 0;
          border: 0;
          background: transparent;
        }

        li a:hover,
        li.active a {
          color: $tabs-vertical-active-color;
        }
      }

      .tab-content {
        padding: 0 0 0 60px;
      }
    }


    @media (min-width: $screen-sm-min) {
      .nav-custom-tabs {
        min-width: 140px;
      }
    }

    @media (min-width: $screen-lg-min) {
      .tab-content {
        padding: 0 0 0 110px;
      }

      .nav-custom-tabs {
        min-width: 190px;
      }
    }
  }
}

.page {
  .tabs-custom-wrap{
    z-index: 1;

    .tab-content-inner{
      position: relative;
    }

    @media (min-width: $screen-lg-min) {
      padding-left: 40px;
    }
  }

  .tabs-custom-wrap + .range {
    margin-top: 0;
  }
}