/*
*
* Counter
* --------------------------------------------------
*/


.counter {
  font-size: 48px;
  line-height: 1;
  letter-spacing: 0.025em;
  color: $gray-base;

  &__subtitle{
    margin-top: 3px;
    text-transform: uppercase;
    color: $snow;
  }

  &-wrap{
    position: relative;
    padding: 37px 20px;

    &:before,
    &:after{
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border: 1px solid #959595;
    }

    &:before{
      transform: translate(-5px, -5px);
    }
  }
}