/*
*
* Page Loaders
* --------------------------------------------------
*/


// Base styles
// -------------------------

.page-loader {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 9999999;
  background: $gradient;
  transition: .3s all ease;

  &.loaded {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }

  &.ending {
    display: none;
  }

  .page-loader-body {
    text-align: center;
    z-index: 1;
  }
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../images/bg.png');
    background-size: cover;
    z-index: -1;
  }
}

// Custom Styles
// -------------------------
.page-loader {
  h6 {
    margin-top: 15px;
    color: $white;
    letter-spacing: .1em;
  }
}