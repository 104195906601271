/*
*
* RD Google Map
* --------------------------------------------------
*/

$map-height: 200px;
$map-xs-height: 250px;
$map-md-height: 450px;


.gm-style-pbt{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #212121;
  color: #fff;
  padding: 5px 14px;
  font-size: 18px;
  border-radius: 3px;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  text-align: center;
  max-width: 320px;
}

.rd-google-map__model {
    color: $gray-base;
    height: $map-height;

    img {
      max-width: none !important;
    }

    @media (min-width: $screen-xs-min) {
      height: $map-xs-height;
    }

    @media (min-width: $screen-md-min) {
      height: $map-md-height;
    }
}

.map_locations {
  display: none;
}
