/*
*
* Form styles
* --------------------------------------------------
*/

//== Forms variables
//

$input-height: 46px;

$form-wrap-offset: 25px;

$textarea-default-height:       90px;
$textarea-default-min-height:   $textarea-default-height * 0.8;
$textarea-default-max-height:   $textarea-default-height * 1.7;

$input-color: #9c9c9c;
$input-color-placeholder: $gray-base;

$input-bg: transparent;

$input-border: $gray-lighter;
$input-border-radius: 0;

$input-font-size: 14px;
$input-line-height: 22px;
$input-font-weight: 400;

$input-padding-vertical: 12px;
$input-padding-horizontal: 0;

$error-color: #d9534f;
$success-color: #5cb85c;



.rd-mailform {
  position: relative;
  text-align: left;
}

html .rd-mailform-inline{
  text-align: center;

  @media (min-width: 480px) {
    @include display-flex();
    align-items: flex-end;
  }

  .form-wrap{
    @include flex-grow(1);
  }

  .button{
    margin-top: 10px;
    flex: none;

    @media (min-width: 480px) {
      margin-top: 0;
      margin-left: 30px;
    }
  }
}

.recaptcha{
  mix-blend-mode: darken;
  transform: scale(0.9);
  transform-origin: left center;
}


// Form input
// -------------------------

.form-input {
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-vertical $input-padding-horizontal;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-image: none;
  border: none;
  border-bottom: 1px solid $input-border;
  border-radius: $input-border-radius;
  -webkit-appearance: none;

  &:focus {
    outline: 0;
    border-bottom: 2px solid $primary-dr;
  }

  &:-webkit-autofill{
    background-color: $input-bg;
  }
  
  &:-webkit-autofill ~ label,
  &:-webkit-autofill ~ .form-validation {
    color: $gray-base !important;
  }
}

textarea.form-input {
  height: $textarea-default-height;
  min-height: $textarea-default-min-height;
  max-height: $textarea-default-max-height;
  resize: vertical;
}

// Form wrapper
// -------------------------

.form-wrap {
  position: relative;
}

.form-wrap-lg{
  .form-input{
    height: 60px;
  }

  .form-label.focus {
    top: -10px;
  }
}

.page .form-button-abs{
  position: relative;

  .form-input{
    padding-right: 50px;
  }

  .button{
    position: absolute;
    right: 10px;
    top: 13px;
    margin: 0;
    padding: 0;
    font-size: 32px;
    line-height: 1.1;
    background: none;
    border: none;
    
    &:hover{
      color: $secondary;
    }
  }
}

.form-wrap-gray{
  .form-input{
    padding: 12px 20px;
    background-color: #f3f4f9;
    border: none;
    color: #9b9b9b;
  }

  .form-label{
    top: 30px;
    left: 20px;
    color: #9b9b9b;
  }
}

.page .form-wrap-outline{
  .form-input{
    padding-left: 10px;
    padding-right: 10px;
    background: none;
    border: 1px solid #e1e1e1;
  }
}

.form-wrap + .form-wrap{
  margin-top: $form-wrap-offset;
}

* + .form-button,
.form-wrap + .button{
  margin-top: 30px;
}


// Form labels
// -------------------------

.form-label {
  position: absolute;
  top: $input-height / 2;
  left: $input-padding-horizontal;

  font-size: $input-font-size;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color-placeholder;
  pointer-events: none;
  z-index: 1;
  transition: .3s;
  transform: translateY(-50%);

  &.focus {
    top: 0;
    //transform: translateY(-100%);
  }

  &.auto-fill {
    color: $input-color;
  }
}

.form-label-outside {
  @media (min-width: $screen-sm-min) {
    position: static;
    &, &.focus, &.auto-fill {
      opacity: 1;
      transform: none;
      color: $input-color-placeholder;
      font-size: $input-font-size;
    }

    & + .form-input + .form-validation{
      top: 32px;
    }
  }
}


// Form validation
// -------------------------

.form-validation {
  position: absolute;
  right: 0;
  bottom: 3px;
  font-size: 11px;
  line-height: 11px;
  color: $error-color;
  margin-top: 3px;
  transition: .3s;
  z-index: 1;
}

.form-validation-left {
  .form-validation {
    right: auto;
    top: 100%;
    left: 0;
  }
}


// Form output
// -------------------------

#form-output-global {
  position: fixed;
  bottom: 0;
  visibility: hidden;
  transform: translateY(calc(100% + 30px));
  transition: .3s all ease;
  z-index: 9999999;
  margin: 15px;

  &.active {
    transform: translateY(0);
    visibility: visible;
  }

  @media (min-width: $screen-sm) {
    margin: 30px;
  }
}

.form-output {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 2px;
  transition: .3s;
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &.error {
    color: $error-color;
  }

  &.success {
    color: $success-color;
  }
}


// Radio and Checkbox Custom
// -------------------------


// Base Styles
//

.radio .radio-custom,
.radio-inline .radio-custom,
.checkbox .checkbox-custom,
.checkbox-inline .checkbox-custom {
  opacity: 0;

  &, &-dummy {
    position: absolute;
    width: 18px;
    height: 18px;
    margin-left: -20px;
    margin-top: 2px;
    outline: none;
    cursor: pointer;
  }

  &-dummy {
    pointer-events: none;
    background: transparent;
    border: 2px solid #e8e8e8;

    &:after {
      position: absolute;
      opacity: 0;
    }
  }

  &:focus {
    outline: none;
  }
}

.radio-custom:checked + .radio-custom-dummy:after,
.checkbox-custom:checked + .checkbox-custom-dummy:after {
  opacity: 1;
}


// Custom Radio Styles
//

.radio,
.radio-inline {
  .radio-custom-dummy {
    border-radius: 50%;

    &:after {
      content: '';
      top: 3px;
      right: 3px;
      bottom: 3px;
      left: 3px;
      background: $gray-darker;
      border-radius: 50%;
    }
  }
}


// Custom Checkbox Styles
//

.checkbox,
.checkbox-inline {
  color: $gray-light;
  padding-left: 32px;

  .checkbox-custom-dummy {
    pointer-events: none;
    margin-left: 0;
    left: 0;

    &:after {
      content: '\f222';
      font-family: "Material Design Icons";
      font-size: 20px;
      line-height: 10px;
      position: absolute;
      top: 0;
      left: 0;
      color: $gray-darker;
    }
  }
}