/*
*
* Custom Tables
* --------------------------------------------------
*/


.table-custom-wrap{
  overflow-x: auto;
}

.table-custom {
  width: 100%;
  text-align: left;

  *{
    white-space: nowrap;
  }

  tr:first-child {
    td {
      font-weight: 700;
    }
  }

  th tbody, td tbody {
    padding: 7px 18px;
  }

  @media (min-width: $screen-md-min) {
    th, td {
      &:nth-child(n+2) {
        padding-left: 56px;
      }
    }
  }
}

.table-custom {
  font-size: 14px;
  text-align: left;

  th {
    color: $gray-darker;
    font-weight: 400;
  }

  td {
    color: #888a8c;
  }

  tbody {
    tr {
      border: 1px solid #f5f5f5;
      border-left: none;
      border-right: none;
      
      &:first-child {
        border: 1px solid #f5f5f5;
      }
      
      &:last-child td{
        color: #34495e;
      }
      
      td, th {
        border: none;
        vertical-align: middle;
        padding: 13px 23px;
      }
      
      th, td {
        padding: 4px 16px;
      }
    }
  }

  &.table-fixed {
    @media (min-width: 768px) {
      table-layout: fixed;
    }

    th tbody, td tbody {
      padding: 0;
    }

    @media (min-width: $screen-md-min) {
      th, td {
        &:nth-child(n+2) {
          padding-left: 0;
        }
        padding: 13px 23px;
      }
    }
  }

  &.table-hover-rows,
  &.striped-table,
  &.condensed-table {
    tbody {
      tr {
        &:first-child {
          border: none;
          border-bottom: 1px solid $gray-darker;
        }
      }
    }
  }

  &.table-hover-rows {
    tbody {
      tr {
        &:nth-child(n+2) {
          &:hover {
            background: $gray-lighter;
            cursor: pointer;
          }
        }
      }
    }
  }

  &.striped-table,
  &.condensed-table,
  &.bordered-table {
    tbody {
      tr {
        &:hover {
          background: none;
          cursor: auto;
        }
        &:nth-child(odd) {
          background: rgba(#1f2932, 0.05);
        }
      }
    }
  }

  &.bordered-table {
    tbody {
      tr {
        border: 1px solid #f5f5f5;
        td, th {
          border: 1px solid #f5f5f5;
        }
        th {
          border-bottom: none;
        }
        &:nth-child(n+2) {
          td {
            border-top: none;
          }
        }
        &:nth-child(even) {
          background: none;
        }
      }

      @media (min-width: $screen-md-min) {
        th, td {
          &:nth-child(n+2) {
            padding-left: 20px;
          }
          padding: 12px 19px;
        }
      }
    }
  }
  &.table-gray-darker {
    border: 1px solid #f5f5f5;

    tbody {
      tr {
        &:first-child {
          th {
            font-size: 14px;
            font-family: $font-family-sec;
            font-weight: 600;
            text-transform: uppercase;
          }
        }
        th {
          background: $gray-darker;
          color: $white;
          border-top-color: #f5f5f5;
        }
      }
    }

    &.condensed-table{
      border: none;

      tbody tr{
        border: none;

        &:last-child{
          outline: 1px solid #f5f5f5;
        }
      }
    }
  }
  &.table-gray-light {
    border: 1px solid #f5f5f5;

    tbody {
      tr {
        &:first-child {
          th {
            font-size: 14px;
            font-family: $font-family-sec;
            font-weight: 600;
            text-transform: uppercase;
          }
        }
        th {
          background: #f5f5f5;
          border-top-color: #f5f5f5;
        }
      }
    }
  }
}