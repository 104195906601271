// Core variables and mixins
@import "lightgallery/_lg-variables";
@import "lightgallery/_lg-mixins";
@import "lightgallery/_lg-fonts";
@import "lightgallery/_lg-theme-default";
@import "lightgallery/_lg-thumbnail";
@import "lightgallery/_lg-video";
@import "lightgallery/_lg-autoplay";
@import "lightgallery/_lg-zoom";
@import "lightgallery/_lg-pager";
@import "lightgallery/_lg-fullscreen";
@import "lightgallery/_lg-share";
@import "lightgallery/_lg-animations";


// Clearfix
.lg-group:after {
    content: "";
    display: table;
    clear: both;
}

// lightgallery core
.lg-outer {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-outer;
    opacity: 0;
    // For start/end transition
    @include transition(opacity 0.15s ease 0s);

    direction: ltr;

    * {
        @include box-sizing(border-box);
    }

    &.lg-visible {
        opacity: 1;
    }

    // Set transition speed and timing function
    &.lg-css3 {
        .lg-item {
            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transition-duration(inherit !important);
                @include transition-timing-function(inherit !important);
            }
        }
    }

    // Remove transition while dragging
    &.lg-css3.lg-dragging {
        .lg-item {
            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transition-duration(0s !important);
                opacity: 1;
            }
        }
    }

    // Set cursor grab while dragging
    &.lg-grab {
        img.lg-object {
            @include grab-cursor;
        }
    }

    &.lg-grabbing {
        img.lg-object {
            @include grabbing-cursor;
        }
    }

    .lg {
        height: 100%;
        width: 100%;
        position: relative;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        max-height: 100%;
    }

    .lg-inner {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        white-space: nowrap;
    }

    .lg-item {
        background: url("#{$lg-path-images}/loading.gif") no-repeat scroll center center transparent;
        display: none !important;
    }
    &.lg-css3{
        .lg-prev-slide, .lg-current, .lg-next-slide{
            display: inline-block !important;
        }
    }
    &.lg-css{
        .lg-current{
            display: inline-block !important;
        }
    }

    .lg-item, .lg-img-wrap {
        display: inline-block;
        text-align: center;
        position: absolute;
        width: 100%;
        height: 100%;

        &:before {
            content: "";
            display: inline-block;
            height: 50%;
            width: 1px;
            margin-right: -1px;
        }
    }

    .lg-img-wrap {
        position: absolute;
        padding: 0 5px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0
    }

    .lg-item {
        &.lg-complete {
            background-image: none;
        }

        &.lg-current {
            z-index: $zindex-item;
        }
    }

    .lg-image {
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
        max-height: 100%;
        width: auto !important;
        height: auto !important;
    }

    &.lg-show-after-load {
        .lg-item {
            .lg-object, .lg-video-play {
                opacity: 0;
                @include transition(opacity 0.15s ease 0s);
            }

            &.lg-complete {
                .lg-object, .lg-video-play {
                    opacity: 1;
                }
            }
        }
    }

    // Hide title div if empty
    .lg-empty-html {
        display: none;
    }

    &.lg-hide-download{
        #lg-download{
            display: none;
        }
    }
}
.lg-backdrop{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $zindex-backdrop;
    background-color: #000;
    opacity: 0;
    @include transition(opacity 0.15s ease 0s);
    &.in{
        opacity: $backdrop-opacity;
    }
}

// Default slide animations. Should be placed at the bottom of the animation css
.lg-css3 {

    // Remove all transition effects
    &.lg-no-trans {
        .lg-prev-slide, .lg-next-slide, .lg-current {
            @include transitionCustom(none 0s ease 0s !important);
        }
    }

    &.lg-use-css3 {
        .lg-item {
            @include backface-visibility(hidden);
        }
    }

    &.lg-use-left {
        .lg-item {
            @include backface-visibility(hidden);
        }
    }

    // Fade mode
    &.lg-fade {
        .lg-item {
            opacity: 0;

            &.lg-current {
                opacity: 1;
            }

            // transition timing property and duration will be over written from javascript
            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide {
        &.lg-use-css3 {
            .lg-item {
                opacity: 0;

                &.lg-prev-slide {
                    @include translate3d(-100%, 0, 0);
                }

                &.lg-next-slide {
                    @include translate3d(100%, 0, 0);
                }

                &.lg-current {
                    @include translate3d(0, 0, 0);
                    opacity: 1;
                }

                // transition timing property and duration will be over written from javascript
                &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                    @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
                }
            }
        }

        &.lg-use-left {
            .lg-item {
                opacity: 0;
                position: absolute;
                left: 0;

                &.lg-prev-slide {
                    left: -100%;
                }

                &.lg-next-slide {
                    left: 100%;
                }

                &.lg-current {
                    left: 0;
                    opacity: 1;
                }

                // transition timing property and duration will be over written from javascript
                &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                    @include transitionCustom(left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
                }
            }
        }
    }
}

.lg-item{
    iframe{
        background-color: $white;
    }
}

.lg-thumb-item{
    padding-bottom: 8%;
    position: relative;
    height: auto;

    img{
        position: absolute;
        width: auto !important;
        max-width: none;
    }
}

.lightgallery-album{
    > li:not(:first-child){
        display: none;
    }
}

[data-lightgallery='item'],
[data-lightgallery="group-item"]{
    cursor: pointer;
}




// Light skin

.lg-skin-light{
    &.lg-backdrop {
        background-color: #f1f1f1;
    }

    &.lg-outer{
        .lg-sub-html{
            background-color: rgba(224, 223, 223, 0.95);
            color: #2b2b2b;
        }

        .lg-progress-bar .lg-progress{
            background-color: #fff;
        }

        .lg-img-wrap{
            padding-bottom: 110px;
            padding-top: 58px;
        }

        .lg-actions .lg-next, .lg-actions .lg-prev{
            background-color: rgba(43, 43, 43, 0.55);
            border-radius: 2px;
            color: #e0e0e0;

            &:hover{
                background-color: rgba(43, 43, 43, 0.75);
            }
        }

        #lg-counter,
        .lg-toolbar .lg-icon,
        &.lg-dropdown-active #lg-share{
            color: #353535;
        }

        .lg-outer .lg-thumb-outer{
            background-color: rgba(247, 247, 247, 0.9);
        }

        .lg-toolbar .lg-icon,
        .lg-toogle-thumb{
            &:hover{
                color: #555;
            }
        }

        .lg-toogle-thumb{
            color: #353535;
        }

        .lg-thumb-outer,
        .lg-toogle-thumb{
            background-color: rgba(224, 223, 223, 0.95);
        }

        .lg-toolbar{
            background-color: rgba(224, 223, 223, 0.95);
        }
    }
}

// Skin center

.lg-skin-center{
    #lg-actual-size,
    #lg-zoom-in,
    #lg-zoom-out{
        display: none;
    }
}


// Skin 1

.lg-skin-1{
    &.lg-outer{
        #lg-actual-size,
        #lg-download,
        #lg-counter,
        .lg-autoplay-button,
        .lg-sub-html{
            display: none;
        }

        .lg-toogle-thumb,
        .lg-thumb-outer{
            background-color: rgba(0,0,0,.6);
            transition: .3s;
        }

        &.lg-thumb-open{
            .lg-thumb-outer {
                transform: translate3d(0, 85%, 0);
            }

            .lg-toogle-thumb,
            .lg-thumb-outer{
                background-color: rgba(0,0,0,.4);
            }
        }

        .lg-thumb-outer{
            transform: translate3d(0, 0, 0);
        }

        .lg-thumb-item{
            border-radius: 0;
            opacity: .5;
            transform: none;
        }

        .lg-thumb-item.active,
        .lg-thumb-item:hover{
            transform: none;
            opacity: .9;
        }

        .lg-object.lg-image{
            position: absolute;
            left: 0;
            top: 0;
            width: 100vw !important;
            height: 100vh !important;
            object-fit: cover;
        }
    }
}

html.ie-11,
html.ie-10{
    .lg-skin-1{
        .lg-image.lg-object{
            position: static;
            width: auto !important;
        }

        .lg-img-wrap{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

// Skin 2

.lg-skin-2{
    background-color: $white;

    .lg-item.lg-zoomable{
        overflow: hidden;
    }

    #lg-dropdown-overlay{
        display: none;
    }

    @media (max-height: 600px) {
        #lg-share,
        .lg-actions,
        .lg-sub-html,
        .lg-thumb-outer{
            display: none !important;
        }

        &.lg-outer .lg-inner{
            bottom: 25px !important;
        }
    }

    @media (max-height: 600px) {
        #lg-share,
        .lg-actions,
        .lg-sub-html,
        .lg-thumb-outer{
            display: none !important;
        }

        &.lg-outer .lg-inner{
            bottom: 25px !important;
        }
    }

    @media (max-width: $screen-sm-min - 1) {
        .lg-dropdown {
            box-shadow: 1px -3px 14px 0 rgba(0,0,0,.14);
        }
    }

    @media (min-width: $screen-sm-min) {
        #lg-share{
            display: block;
            width: auto;
            position: static;
            will-change: opacity;

            &:after{
                content: none;
            }

            .lg-dropdown{
                will-change: opacity;
                position: fixed !important;
                max-width: 960px;
                width: 100%;
                justify-content: flex-end;
                top: auto;
                left: 50%;
                transform: translateX(-50%);
                background-color: transparent;
                padding: 0;
                display: flex;
                visibility: visible;
                pointer-events: none;

                &:before{
                    letter-spacing: 0;
                    font-size: 14px;
                    color: #9b9b9b;
                    font-family: $font-family-base;
                    margin-right: 10px;
                    content: 'Share this photo';
                }

                li{
                    margin-right: 8px;
                }

                a{
                    pointer-events: all;
                    padding: 0;
                    border-radius: 50%;

                    &:hover{
                        background-color: transparent;
                    }
                }
            }

            .lg-icon{
                display: block;
                font-size: 14px;
                font-family: FontAwesome;
                border-radius: 50%;
                margin: 0;
                width: 27px;
                height: 27px;
                line-height: 27px;
                text-align: center;
                background-color: gray;
                color: $white;
                transition: .3s;

                &:hover{
                    background-color: $gray !important;
                }

                &:after{
                    content: none;
                }

                &:before{
                    content: '\f1e0';
                }
            }

            .lg-dropdown-text{
                display: none;
            }

            #lg-share-facebook{
                .lg-icon{
                    background-color: #4477bb;
                    &:before{
                        content: '\f09a';
                    }
                }
            }

            #lg-share-twitter{
                .lg-icon{
                    background-color: #69bff0;
                    &:before{
                        content: '\f099';
                    }
                }
            }

            #lg-share-googleplus{
                .lg-icon{
                    background-color: #f06976;
                    &:before{
                        content: '\f0d5';
                    }
                }
            }

            #lg-share-pinterest{
                .lg-icon{
                    background-color: #e46868;
                    &:before{
                        content: '\f231';
                    }
                }
            }
        }
    }

    &.lg-outer .lg-inner{
        height: auto;
        top: 60px;
        bottom: 220px;

        @media (min-width: $screen-xs-min) {
            bottom: 190px;
        }
    }

    .lg-actions{
        max-width: 980px;
        width: 100%;
        justify-content: space-between;
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        display: none;

        @media (min-width: $screen-xs-min) {
            display: flex;
        }

        .lg-next,
        .lg-prev{
            position: static;
            opacity: 1;
            font-size: 52px;
            color: #a0a3b0;
            transform: none;
            background-color: transparent;

            &:after{
                content: none;
            }

            &:before{
                font-family: "FontAwesome";
            }

            &:hover{
                color: $secondary;
            }
        }


        .lg-next{
            &:before{
                content: '\f105';
            }
        }
        .lg-prev{
            &:before{
                content: '\f104';
            }
        }
    }

    .lg-thumb{
        padding: 0;
    }

    .lg-toogle-thumb{
        display: none;
    }

    &.lg-outer .lg-thumb-outer{
        transform: translateX(-50%);
    }

    .lg-thumb-outer{
        overflow: hidden;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 20px;
        margin-bottom: 20px;
        background-color: transparent;

        @media (min-width: $screen-xs-min) {
            width: calc(100vw - 130px);
        }

        @media (min-width: 1050px) {
            max-width: 820px;
        }

        .lg-thumb-item{
            transform: none;
            border-radius: 0;
            background-color: #484e70;

            img {
                transition: .4s;
            }

            &.active{
                img{
                    opacity: .65;
                }
            }
        }
    }

    &.lg-outer.lg-pull-caption-up .lg-sub-html{
        opacity: 1;
        bottom: 140px;

        @media (min-width: $screen-xs-min) {
            bottom: 140px;
        }
    }

    &.lg-outer{
        .lg-thumb-outer,
        .lg-actions{
            opacity: 1;
        }

        .lg-dropdown{
            opacity: 1;

            @media (min-width: $screen-sm-min) {
                bottom: 161px;
            }
        }
    }

    .lg-dropdown{
        opacity: 0;
        transition: .3s;

        @media (min-width: $screen-sm-min) {
            bottom: 120px;
        }
    }

    .lg-thumb-outer,
    .lg-actions{
        opacity: 0;
        transition: .3s .3s;
    }

    &.lg-outer .lg-sub-html{
        bottom: 120px;
        opacity: 0;
        transition: .3s;
        max-width: 960px;
        //margin-left: auto;
        //margin-right: auto;
        text-align: left;
        background-color: transparent;
        border-bottom: 1px solid #e1e1e1;
        padding: 10px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;

        @media (min-width: 1050px) {
            padding: 10px 0;
        }

        p{
            display: inline-block;
        }

        p:not(.small){
            font-size: 16px;
            font-weight: 500;
            font-family: $font-family-sec;
            color: $gray-base;
            letter-spacing: 0;
        }

        .small{
            letter-spacing: .025em;
            font-size: 14px;
            color: #9b9b9b;
        }

        p + p{
            margin-left: 10px;
        }
    }

    &.lg-outer.lg-dropdown-active #lg-share{
        color: $secondary;
    }
    
    .lg-toolbar{
        background-color: transparent;

        .lg-icon{
            height: 55px;
            width: 55px;
            line-height: 33px;
            display: none;
            color: $gray-base;

            &:hover{
                color: $secondary;
            }
        }

        #lg-share{
            display: block;
        }

        .lg-close{
            display: block;
            font-size: 32px;
        }
    }

    &.lg-hide-items .lg-toolbar{
        opacity: 1;
        transform: none;
    }
}

html.desktop{
  .lg-skin-2.lg-outer .lg-inner{
    @media (min-width: $screen-xs-min) {
      bottom: 210px;
    }
  }
}