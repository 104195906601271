/*
* Fullwidth Layout
*/

.rd-navbar-fullwidth {
  display: block;

  // RD Navbar Inner
  .rd-navbar-inner{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  // RD Navbar right aside
  .rd-navbar-aside-right{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  // RD Navbar Nav
  .rd-navbar-nav {
    > li + li {
      margin-left: $navbar-fullwidth-nav-indent;
    }
  }

  // RD Navbar States
  &.rd-navbar--is-stuck,
  &.rd-navbar--is-clone{
    .rd-navbar-inner{
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .rd-navbar-aside-right{
      margin-top: 0;
    }

    .rd-navbar-panel{
      display: none;
    }
  }
}