/*
*
* Panels Custom
* --------------------------------------------------
*/

//== Panel variables
//
//##

$panel-title-color: $gray-base;
$panel-title-background: #f3f4f9;
$panel-title-padding: 10px 50px 10px 24px;
$panel-title-sm-padding: 17px 60px 16px 34px;
$panel-active-background: #f3f4f9;
$panel-arrow: '\f107';
$panel-body-padding: 10px 40px 32px 33px;
$panel-body-sm-padding: 18px 40px 32px 33px;


// Base Style
// --------------------------------------------------

.panel {
  margin-bottom: 0;
  box-shadow: none;
  border: none;
  border-radius: 0;
}

.panel-custom {
  border-radius: 4px;
  overflow: hidden;

  &.panel-custom-default {
    border-top: 1px solid #f2f2f2;

    color: #9b9b9b;
  }

  .panel-custom-title {
    font-family: $font-family-sec;
    font-weight: 500;
    color: $gray;

    @media (min-width: 768px) {
      font-size: 18px;
    }

    > a {
      display: block;
      position: relative;
      padding: $panel-title-padding;
      color: $panel-title-color;
      background: $panel-active-background;
      transition: .3s all ease;

      &:before {
        content: '-';
        position: absolute;
        top: 50%;
        right: 10px;
        font-weight: 400;
        text-align: center;
        background-color: #2f353f;
        border-radius: 50%;
        color: $white;
        transform: translateY(-50%) rotate(-180deg);
        transition: .3s all ease;

        width: 26px;
        height: 26px;
        font-size: 20px;
        line-height: 29px;

        @media (min-width: 768px) {
          width: 42px;
          height: 42px;
          font-size: 30px;
          line-height: 46px;
        }
      }

      &.collapsed {
        background: $panel-title-background;

        &:before {
          content: '+';
        }
      }

      &:hover {
        text-decoration: none;
        background: $panel-active-background;
      }

      @media (min-width: $screen-sm-min) {
        padding: $panel-title-sm-padding;
      }
    }
  }

  .panel-custom-collapse {
    background: $panel-active-background;

    &.in{
      border-top: 1px solid #dde1f0;
    }

    .panel-custom-body {
      padding: $panel-body-padding;

      @media (min-width: $screen-sm-min) {
        padding: $panel-body-sm-padding;
      }
    }
  }
}

.panel-custom.panel-white{
  border-radius: 0;
  box-shadow: 0 2px 5px 0px rgba(41,41,41,.15);
  .panel-custom-title a,
  .panel-custom-body{
    background-color: #fff;
  }

  .panel-custom-title a{
    &:before{
      color: $primary;
    }
  }
}

.panel-custom + .panel-custom{
  margin-top: 30px;
}

h3 + .panel-custom-group-wrap {
  margin-top: 30px;
}