/*
*
* RD Navbar
* --------------------------------------------------
*/


//== RD Navbar variables
//
//##

$rd-navbar-min-font-size: 24px;
$rd-navbar-min-line-height: 48px;
$rd-navbar-min-height: 56px;

$rd-navbar-color: $white;
$rd-navbar-background: $gray-darker;
$rd-navbar-shadow: 0 0 22px -4px rgba(0, 0, 0, 0.17);
$rd-navbar-width: 1890px;

//RD Navbar panel
$rd-navbar-panel-color: $rd-navbar-color;
$rd-navbar-panel-background: $rd-navbar-background;

//RD Navbar nav
$rd-navbar-nav-min-width: 290px;
$rd-navbar-nav-color: $rd-navbar-color;
$rd-navbar-nav-background: transparent;
$rd-navbar-nav-hover-color: $primary;
$rd-navbar-nav-hover-background: transparent;
$rd-navbar-nav-active-color: $rd-navbar-nav-hover-color;
$rd-navbar-nav-active-background: $rd-navbar-nav-hover-background;

//RD Navbar dropdown
$rd-navbar-dropdown-width: 290px;
$rd-navbar-dropdown-background: #B1A5E8;
$rd-navbar-dropdown-item-color: $white;
$rd-navbar-dropdown-item-background: transparent;
$rd-navbar-dropdown-item-hover-color: $primary;
$rd-navbar-dropdown-item-hover-background: transparent;
$rd-navbar-dropdown-item-active-color: $rd-navbar-dropdown-item-hover-color;
$rd-navbar-dropdown-item-active-background: $rd-navbar-dropdown-item-hover-background;

//RD Navbar megamenu
$rd-navbar-megamenu-gutter: 30px;
$rd-navbar-megamenu-background: #B1A5E8;
$rd-navbar-megamenu-item-color: $rd-navbar-dropdown-item-color;
$rd-navbar-megamenu-item-background: transparent;
$rd-navbar-megamenu-item-hover-color: $rd-navbar-dropdown-item-hover-color;
$rd-navbar-megamenu-item-hover-background: transparent;

//RD Navbar togles
$rd-navbar-toggle-preset: "button-lines-arrow-2";
$rd-navbar-collapse-toggle-preset: "collapse-preset-1";

//RD Navbar Fixed
$rd-navbar-fixed-height: $rd-navbar-min-height;
$rd-navbar-fixed-line-height: $rd-navbar-min-line-height;
$rd-navbar-fixed-toggle-color: $white;

$rd-navbar-fixed-panel-color:  $white;
$rd-navbar-fixed-panel-background: $gray-base;

$rd-navbar-fixed-nav-color: $rd-navbar-color;
$rd-navbar-fixed-nav-background: $rd-navbar-background;

$rd-navbar-fixed-nav-active-color: $gray-base;
$rd-navbar-fixed-nav-active-background: $primary;

$rd-navbar-fixed-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.25);

//RD Navbar indents
$navbar-static-nav-indent: 38px;
$navbar-fullwidth-nav-indent: 20px;


@import "rd-navbar_includes/_rd-navbar-mixins";


// RD Navbar Basics
%rd-navbar-transition {
  transition: 0.35s all cubic-bezier(0.65, 0.05, 0.36, 1);
}

%rd-navbar-hidden {
  display: none;
}

%rd-navbar-visible {
  display: block;
}

.rd-navbar--no-transition {
  &, * {
    transition: none !important;
  }
}

.rd-navbar-wrap {
  position: relative;
  z-index: 1;
  @extend %rd-navbar-transition;
}

.rd-navbar, .rd-navbar.rd-navbar--is-clone {
  @extend %rd-navbar-hidden;
}

.rd-navbar-fixed,
.rd-navbar-static,
.rd-navbar-fullwidth,
.rd-navbar-sidebar {
  @extend %rd-navbar-visible;
}

.rd-navbar,
.rd-navbar-dropdown,
.rd-navbar-megamenu,
.rd-navbar-nav,
.rd-navbar-panel{
  @extend %rd-navbar-transition;
}


// RD Navbar Collapse
.rd-navbar-collapse {
  &-toggle {
    @include make-toggle(
            $rd-navbar-collapse-toggle-preset,
            $rd-navbar-min-line-height,
            $rd-navbar-min-font-size,
            $rd-navbar-panel-color
    );
    display: none;
  }
}

.rd-navbar--is-stuck {
  box-shadow: $rd-navbar-shadow;
}

.rd-navbar.rd-navbar-fixed + .rd-navbar.rd-navbar--is-clone,
.rd-navbar.rd-navbar-sidebar + .rd-navbar.rd-navbar--is-clone {
  display: none;
}

@import "rd-navbar_includes/_rd-navbar-components";


/*
* @subsection   Hybrid  Styles
*/

//=============    Static & Fullwidth     ================
.rd-navbar-static,
.rd-navbar-fullwidth {
  will-change: transform;

  .rd-navbar-brand{
    display: flex;
    align-items: center;
    color: $white;

    .brand-slogan{
      display: inline-block;
      margin-left: 18px;
      padding-left: 18px;
      border-left: 1px solid #6e7279;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: .15em;
      font-weight: 500;
    }
  }

  .rd-navbar-aside-right{
    align-items: center;
  }

  .rd-navbar-socials{
    margin-left: 20px;
    position: relative;

    .rd-navbar-collapse{
      display: block;
      position: absolute;
      opacity: 0;
      visibility: hidden;

      right: 50%;
      transform: translateX(50%);
      margin-top: 20px;
      transition: .2s;

      li + li{
        margin-top: 10px;
      }

      &.active{
        margin-top: 0;
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .rd-navbar-socials-toggle{
    display: flex;
    color: $white;

    @include toggle-icons-via-rotation(48px, 48px, 24px, '\f586', '\f24c', 'Material Design Icons', 'Material Design Icons');
  }


  // RD Navbar Nav
  .rd-navbar-nav {
    font-family: $font-family-sec;
    > li {
      display: inline-block;
      transition: .25s;

      > a {
        position: relative;
        display: inline-block;
        color: $rd-navbar-nav-color;
        line-height: 1.2;
        font-weight: 500;
        font-size: 16px;
        transition: .25s;
        &:before {
          position: absolute;
          content: '';
          bottom: -5px;
          left: 0;
          width: 0;
          height: 2px;
          background: $white;
          transition: all .3s;
          
        }

        &:hover{
          color: $rd-navbar-nav-color;
          &:before {
            opacity: 1;
            width: 100%;
          }
        }
      }

      > .rd-navbar-submenu-toggle{
        color: $rd-navbar-nav-color;
        margin-left: 4px;
        font-family: "Material Design Icons";
        font-size: 16px;
        cursor: pointer;

        &:hover{
          color: $rd-navbar-nav-color;
        }

        &:before{
          content: '\f236';
        }
      }

      &.focus,
      &.opened{
        > a{
          color: $rd-navbar-nav-color;
          background: $rd-navbar-nav-hover-background;
          &:before {
            opacity: 1;
            width: 100%;
          }
        }

        > .rd-navbar-submenu-toggle{
          color: $rd-navbar-nav-color;
        }
      }

      &.active {
        > a {
          color: $rd-navbar-nav-color;
          background: $rd-navbar-nav-hover-background;
          &:before {
            opacity: 1;
            width: 100%;
          }
        }

        > .rd-navbar-submenu-toggle{
          color: $rd-navbar-nav-color;
        }
      }
    }
  }

  // RD Navbar Inner
  .rd-navbar-inner {
    position: relative;

    max-width: $rd-navbar-width;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
  }

  // RD Navbar Dropdown
  .rd-navbar-nav {
    > li > .rd-navbar-dropdown {
      position: absolute;
      left: 0;

      width: $rd-navbar-dropdown-width;
      background: $rd-navbar-dropdown-background;
      z-index: 5;
    }

    li.focus,
    li.opened {
      > .rd-navbar-dropdown,
      > .rd-navbar-megamenu {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }

    > li {
      > .rd-navbar-dropdown,
      > .rd-navbar-megamenu {
        z-index: 15;
      }

      > .rd-navbar-dropdown {

        .rd-navbar-dropdown {
          left: 100%;
          top: 0;
          margin-top: -8px;
          margin-left: 8px;
          z-index: 2;
          transform: translate(30px, 0);
        }

        > li.focus,
        > li.opened {
          > .rd-navbar-dropdown {
            opacity: 1;
            visibility: visible;
            transform: translate(0, 0);
          }
        }
      }
    }
  }

  .rd-navbar-dropdown,
  .rd-navbar-megamenu {
    position: absolute;

    display: block;
    padding: 32px 0;
    margin-top: 30px;

    transform: translateY(30px);
    visibility: hidden;
    opacity: 0;

    text-align: left;
    box-shadow: $rd-navbar-shadow;
    @extend %rd-navbar-transition;

    li{
      > a{
        transition: .15s ease-in-out;

        &:before{
          display: inline-block;
          vertical-align: middle;
          content: '';
          height: 1px;
          background-color: $primary;
          width: 0;
          margin-right: 10px;
          margin-left: -10px;
          transition: width .2s ease, opacity .15s ease-in-out, .15s margin ease-in-out;
          opacity: 0;
        }
      }

      &.focus > a,
      &.opened > a,
      > a:hover{
        &:before{
          width: 22px;
          margin-left: 0;
          opacity: 1;
        }
      }
    }
  }

  // RD Navbar Dropdown
  .rd-navbar-dropdown {
    width: $rd-navbar-dropdown-width;
    background: $rd-navbar-dropdown-background;

    > li{
      > a {
        display: block;
        padding: 8px 40px;
        color: $rd-navbar-dropdown-item-color;
        background: $rd-navbar-dropdown-item-background;

        &:hover{
          color: $rd-navbar-dropdown-item-hover-color;
          background: $rd-navbar-dropdown-item-hover-background;
        }
      }

      &.focus,
      &.opened{
        > a{
          color: $rd-navbar-dropdown-item-active-color;
          background: $rd-navbar-dropdown-item-active-background;
        }
      }
    }
  }

  // RD Navbar Megamenu
  .rd-navbar-megamenu {
    display: flex;
    width: 98%;
    max-width: 470px;
    padding: 30px 40px;

    background: $rd-navbar-megamenu-background;

    right: calc(50% - 265px);

    @media (min-width: $screen-lg-min) {
      right: 10px;
    }

    > li {
      padding-right: 40px;
      position: relative;
      width: 50%;
      
      &:after{
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 30px;
        width: 1px;
        background-color: rgba($white, .2);
      }
      
      &:last-child{
        padding-right: 0;

        &:after{
          content: none;
        }
      }

      // Megamenu column
      > ul {
        li + li {
          margin-top: 2px;
        }

        // Megamenu link
        a {
          padding-top: 5px;
          padding-bottom: 5px;
          display: inline-block;
          color: $rd-navbar-megamenu-item-color;
          background: $rd-navbar-megamenu-item-background;

          &:hover {
            color: $rd-navbar-megamenu-item-hover-color;
            background: $rd-navbar-megamenu-item-hover-background;
          }
        }
      }
    }
  }

  // RD Navbar Clone
  &.rd-navbar--is-clone {
    display: block;
    transform: translateY(-100%);

    &.rd-navbar--is-stuck {
      transform: translateY(0%);
    }
  }

  // RD Navbar Stuck
  &.rd-navbar--is-stuck,
  &.rd-navbar--is-clone {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;
    background: $rd-navbar-background;

    .rd-navbar-top-panel{
      display: none;
    }
  }

  // RD Navbar States
  .rd-navbar--has-dropdown {
    position: relative;
  }
}


//=============      Fixed & Sidebar      ================
.rd-navbar-fixed,
.rd-navbar-sidebar {


}


// Scrollbar styles

.rd-navbar-fixed .rd-navbar-nav-wrap,
.rd-navbar-sidebar .rd-navbar-sidebar-inner  {
  width: $rd-navbar-nav-min-width;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  font-size: 16px;
  line-height: 34px;
  color: $rd-navbar-color;
  background: $rd-navbar-fixed-panel-background;
  box-shadow: $rd-navbar-fixed-shadow;
  z-index: 998;

  &:before,
  &:after {
    content: '';
    display: block;
    height: $rd-navbar-min-height;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: lighten($primary, 20%);
    border: none;
    border-radius: 0;
    opacity: .2;
  }

  &::-webkit-scrollbar-track {
    background: $primary;
    border: none;
    border-radius: 0;
  }
}

.rd-navbar-fixed .rd-navbar-nav,
.rd-navbar-sidebar .rd-navbar-nav {


  // RD Navbar Dropdown
  .rd-navbar-dropdown {
    > li > a {
      padding-left: 20px;
    }

    ul > li > a {
      padding-left: 25px;
    }
  }
}

@import "rd-navbar_includes/_rd-navbar-static";
@import "rd-navbar_includes/_rd-navbar-fullwidth";
@import "rd-navbar_includes/_rd-navbar-fixed";
@import "rd-navbar_includes/_rd-navbar-sidebar";


ul ul,
ul ol,
ol ul,
ol ol {
  padding-left: 0;
}
