/*
*
* Posts
* --------------------------------------------------
*/


.post-blog{
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;

  &:hover{
    .post-blog__body{
      box-shadow: 0 13px 29px 0 rgba(0,0,0,.05);
    }

    .post-blog__title{
      padding-left: 19px;
      padding-right: 15px;

      a{
        color: $secondary;
      }

      a:hover{
        color: #476f97;
      }

      &:before{
        transform: rotate(0deg);
        left: 0;
        top: 6px;
        background-color: #476f97;
        width: 2px;
        height: calc(100% - 12px);
        transition: width .15s, height .15s .15s, left .3s, top .3s;
      }
    }
  }

  &__title{
    display: block;
    position: relative;
    transition: .3s;
    padding-left: 34px;
    font-weight: 600;
    font-family: $font-family-sec;
    line-height: 1.66667;
    letter-spacing: 0.025em;
    font-size: 16px;

    a{
      color: $gray-darker;
    }

    @media (min-width: $screen-sm-min) {
      font-size: 18px;
    }

    @media (min-width: $screen-lg-min) {
      padding-right: 6%;
    }

    &:before{
      left: -8px;
      display: block;
      position: absolute;
      content: '';
      width: 22px;
      transform-origin: 0 0;
      background-color: #2f353f;
      height: 2px;
      transition: width .15s .15s, height .15s .0s, left .3s, top .3s;
      top: 11px;

      @media (min-width: $screen-sm-min) {
        top: 15px;
      }
    }
  }

  &__body{
    padding: 25px 28px;
    background-color: $white;
    transition: .3s;
  }

  &__media{

  }

  &__meta{
    margin-top: 20px;

    > *{
      display: inline-block;
      color: #9c9c9c;
      line-height: 1;
      vertical-align: middle;

      &:before{
        display: inline-block;
        margin-right: 4px;
        color: $gray-darker;
        font-size: 16px;
        font-family: "Material Design Icons";
      }

      & + *{
        border-left: 1px solid #cccccc;
        margin-left: 4px;
        padding-left: 8px;

        @media (min-width: $screen-xs-min) {
          margin-left: 14px;
          padding-left: 18px;
        }
      }
    }

    &-date{
      &:before{
        content: '\f246';
      }
    }
    &-comments{
      &:before{
        content: '\f278';
      }
    }
    &-views{
      &:before{
        content: '\f2fd';
      }
    }
  }
}

.post-blog-sm{
  color: #0d0d0d;
  
  &:hover{
    color: $secondary;
  }

  p{
    padding-right: 7px;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.post-blog--single{

  p{
    color: #9b9b9b;
  }

  h3{
    margin-top: 40px;
    margin-bottom: 30px;
    line-height: 36px;
    letter-spacing: .025em;
    color: $gray-base;
  }

  h4{
    text-align: center;
    margin-bottom: 17px;
    margin-top: 93px;
  }

  .post-blog__footer{
    margin-top: 30px;
    padding: 20px;
    background-color: #f3f4f9;

    @media (max-width: $screen-xs-min - 1) {
      text-align: center;
    }

    a:not(.icon){
      color: #9b9b9b;

      &:hover{
        color: initial;
      }
    }
  }

  .post-blog__footer-author{
    &:before{
      display: inline-block;
      margin-right: 4px;
      font-size: 18px;
      line-height: 1;
      vertical-align: middle;
      color: #2f353f;
      content: '\f112';
      font-family: "Material Design Icons";
    }
  }

  .post-blog__footer-share{
    > * {
      display: inline-block;
    }

    p{
      letter-spacing: .05em;
      margin-bottom: 4px;
      margin-right: 10px;
    }
  }

  .quote{
    margin-top: 30px;
    margin-bottom: 40px;

    p{
      color: #00030a;
    }
  }

  hr{
    border-color: #e1e1e1;

    & + p{
      margin-top: 30px;
    }
  }

  .post-blog-single__meta{
    margin-bottom: 20px;
  }

  hr + .comment{
    margin-top: 40px;

    @media (min-width: $screen-md-min) {
      margin-top: 60px;
    }
  }
}