/*
*
* Lists
* --------------------------------------------------
*/


ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}


.list {
  > li + li {
    margin-top: 10px;
  }
}


// List inline
//

.list-inline {
  margin-left: -3px;
  margin-right: -3px;

  > li {
    padding-left: 3px;
    padding-right: 3px;
  }
}


// Description Lists
//

dl {
  margin: 0;
}


// List terms
//

.list-terms {
  margin-top: 35px;
  dt + dd { margin-top: 5px; }
  dd + dt { margin-top: 25px; }
}

.list-terms-2 {
  font-family: $font-family-sec;

  @media (min-width: $screen-md-min) {
    font-size: 16px;
  }

  dt,
  dd{
    font-weight: 400;
    display: inline-block;
  }

  dd{
    color: #9b9b9b;
  }

  dt{
    min-width: 70px;
  }

  & + .list-terms-2{
    margin-top: 8px;
  }
}


// List index
//

.list-index {
  text-align: left;
  padding-left: 24px;

  counter-reset: li;

  li {
    .list-index-counter {
      &:before {
        display: inline-block;
        padding-right: 12px;
        color: $gray-light;
        content: counter(li, decimal)". ";
        counter-increment: li;
      }
    }
  }
}


// List marked
//

.list-marked {
  text-align: left;
  padding-left: 24px;

  li {
    position: relative;
    padding-left: 24px;

    &:before {
      font-family: FontAwesome;
      content: '\f105';
      display: inline-block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      color: $gray-light;
      font-size: 16px;
    }
  }
}


.list-marked-variant-2{
  margin-top: 20px;
  padding-left: 0;

  > li{
    display: flex;
    padding-left: 0;

    > a{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      padding: 5px 0;
      color: #9a9a9a;

      > span:first-child{
        flex: 1;
      }

      &:before{
        content: '';
        display: inline-block;
        width: 5px;
        height: 5px;
        margin-right: 10px;
        margin-bottom: 2px;
        border-radius: 50%;
        background-color: currentColor;
      }
      
      &:hover{
        color: $primary-dr;
      }
    }

    &:before{
      content: none;
    }

    & + li{
      margin-top: 4px;
    }
  }
}