// Unit Utilities
@mixin unit-variant($spacing-t, $spacing-r, $spacing-b, $spacing-l, $breakpoint){
  .unit#{$breakpoint}-middle{
    @include align-items(center);
  }
  .unit#{$breakpoint},
  .unit#{$breakpoint}-vertical {
    @include flex-direction(column);

    > [class*='unit-']:first-child {
      padding-top: 0;
    }

    > .unit__left + .unit__right,
    > .unit__left + .unit__body {
      padding-left: 0;
      padding-top: $spacing-t;
    }

    > .unit__body + .unit__right {
      padding-left: 0;
      padding-top: $spacing-b;
    }
  }

  .unit#{$breakpoint}-horizontal {
    @include flex-direction(row);

    > .unit__left + .unit__right,
    > .unit__left + .unit__body {
      padding-top: 0;
      padding-left: $spacing-l;
    }

    > .unit__body + .unit__right {
      padding-top: 0;
      padding-left: $spacing-r;
    }
  }

  .unit#{$breakpoint}--inverse {
    @include flex-direction(column-reverse);

    &,
    &.unit#{$breakpoint}-vertical {
      @include flex-direction(column-reverse);

      > [class*='unit-']:first-child {
        padding-top: $spacing-t;
      }

      > [class*='unit-']:last-child {
        padding-top: 0;
      }
    }

    &.unit#{$breakpoint}-horizontal {
      @include flex-direction(row-reverse);

      > [class*='unit-']:first-child {
        padding-top: 0;
        padding-left: $spacing-l;
      }

      > [class*='unit-']:last-child {
        padding-left: 0;
      }
    }
  }
}

@mixin unit-spacing-variant($spacing-t, $spacing-r, $spacing-b, $spacing-l, $breakpoint){
  &.unit#{$breakpoint},
  &.unit#{$breakpoint}-vertical {
    > [class*='unit-']:first-child {
      padding-top: 0;
    }

    > .unit__left + .unit__right,
    > .unit__left + .unit__body {
      padding-left: 0;
      padding-top: $spacing-t;
    }

    > .unit__body + .unit__right {
      padding-left: 0;
      padding-top: $spacing-b;
    }
  }

  &.unit#{$breakpoint}-horizontal {
    > .unit__left + .unit__right,
    > .unit__left + .unit__body {
      padding-top: 0;
      padding-left: $spacing-l;
    }

    > .unit__body + .unit__right {
      padding-top: 0;
      padding-left: $spacing-r;
    }
  }

  &.unit#{$breakpoint}--inverse {
    &,
    &.unit#{$breakpoint}-vertical {
      > [class*='unit-']:first-child {
        padding-top: $spacing-t;
      }

      > [class*='unit-']:last-child {
        padding-top: 0;
      }
    }

    &.unit#{$breakpoint}-horizontal {
      > [class*='unit-']:first-child {
        padding-top: 0;
        padding-left: $spacing-l;
      }

      > [class*='unit-']:last-child {
        padding-left: 0;
      }
    }
  }
}

@mixin unit-spacing($medias, $spacing-t, $spacing-r:$spacing-t, $spacing-b:$spacing-t, $spacing-l:$spacing-r){
  @each $resolution, $alias in $medias {
    @if ($alias != '' and $alias != null) {
      $breakpoint: -#{$alias};
      @media (min-width: $resolution) {
        @include unit-spacing-variant($spacing-t, $spacing-r, $spacing-b, $spacing-l, $breakpoint);
      }
    } @else {
      $breakpoint: '';
      @include unit-spacing-variant($spacing-t, $spacing-r, $spacing-b, $spacing-l, $breakpoint);
    }
  }
}

@mixin unit-responsive($medias, $spacing-t, $spacing-r:$spacing-t, $spacing-b:$spacing-t, $spacing-l:$spacing-r) {
  .unit {
    @include display-flex();
    @include flex(0 1 100%);
    @include flex-direction(column);
  }

  .unit__body{
    width: 100%;
    @include flex(0 1 auto);
  }

  .unit__left,
  .unit__right{
    @include flex(0 0 auto);
    max-width: 100%;
  }

  @each $resolution, $alias in $medias {
    @if ($alias != '' and $alias != null) {
      $breakpoint: -#{$alias};
      @media (min-width: $resolution) {
        @include unit-variant($spacing-t, $spacing-r, $spacing-b, $spacing-l, $breakpoint);
      }
    } @else {
      $breakpoint: '';
      @include unit-variant($spacing-t, $spacing-r, $spacing-b, $spacing-l, $breakpoint);
    }
  }
}