/*
*
* Icons
* --------------------------------------------------
*/


// Base styles
// --------------------------------------------------

.icon {
  text-align: center;
  display: inline-block;
  font-size: 16px;
  line-height: 1;

  &:before {
    position: relative;
    display: inline-block;
    font-weight: 400;
    font-style: normal;
    speak: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &[href]{
    transition: .3s;
  }
}


// Alternate icons
// --------------------------------------------------

.icon-default {
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 24px;
  color: $primary;
  background-color: $gray-darker;

  @media (min-width: $screen-xs-min) {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}

.icon-primary {
  color: $primary;
}


// Icon Shapes
// --------------------------------------------------

.icon-circle{
  border-radius: 50%;
}


// Icon Sizes
// --------------------------------------------------

.icon-xs {

  &.icon-circle{
    font-size: 13px;
    width: 27px;
    height: 27px;
    line-height: 27px;
    background-color: $gray;
    color: $white;

    &.fa-facebook{
      background-color: #4477bb;
    }
    &.fa-google-plus{
      background-color: #f06976;
    }
    &.fa-twitter{
      background-color: #69bff0;
    }
    &.fa-pinterest-p{
      background-color: #e46868;
    }
    
    &:hover{
      background: $gray-base;
    }
  }
}

.icon-sm {

}

.icon-lg {
  font-size: 42px;

  @media (min-width: $screen-sm-min) {
    font-size: 68px;
  }
}