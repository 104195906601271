/*
*
* Owl Carousel
* --------------------------------------------------
*/

.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  font: 400 40px/80px 'FontAwesome';
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: scale 100ms ease;
  &:before {
    content: '\f144';
  }
}

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}


.owl-carousel-wide{
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: $screen-sm-min) {
    padding: 0;
  }

  .owl-item{
    .thumbnail-type-4{
      pointer-events: none;
    }

    &.active + *{
      .thumbnail-type-4{
        pointer-events: all;
      }
    }
  }
}


/*
 * Owl Navigation
 */

.owl-nav {

}

.owl-prev, .owl-next {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 28px;
  font-family: "Material Design Icons";
  color: $primary;
  background-color: rgba(#2f353f, .51);

  display: none !important;

  @media (min-width: $screen-md-min) {
    display: block !important;
  }

  &:hover {
    color: $white;
  }
}

.owl-carousel-nav-veil{
  .owl-prev, .owl-next {
    display: none !important;
  }
}

.owl-prev {
  left: 0;

  &:before {
    content: '\f237';
  }
}

.owl-next {
  right: 0;

  &:before {
    content: '\f238';
  }
}

/*
 * Owl Pagination
 */

.owl-dots {
  text-align: center;
  margin-top: 20px;
}

.owl-dot {
  position: relative;

  display: inline-block;
  width: 10px;
  height: 10px;
  border: 2px solid $gray-base;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;

  text-align: center;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  transition: .2s;

  &:hover,
  &:focus {
    background-color: $gray-base;
  }

  &.active {
    background-color: $gray-base;
  }
}

.owl-carousel.owl-carousel-light-dots{
  .owl-dot{
    border-color: $white;

    &:hover,
    &:focus {
      background-color: $white;
    }

    &.active {
      background-color: $white;
    }
  }
}

.owl-carousel-dots-veil-lg{
  .owl-dots{
    @media (min-width: $screen-lg-min) {
      display: none !important;
    }
  }
}

.owl-carousel-nav-right{
  @media (min-width: $screen-md-min) {
    padding-right: 66px;
  }

  .owl-nav{
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .owl-prev{
    border-top: 1px solid #41494d;
    
    &:before{
      content: '\f14a';
    }
  }
  
  .owl-next{
    &:before{
      content: '\f14f';
    }
  }

  .owl-prev,
  .owl-next{
    position: static;
    width: 40px;
    height: 55px;
    transform: none;
    color: rgba(255,255,255,.1);
    font-size: 35px;

    &:hover{
      color: rgba(255,255,255,1);
    }
    
    &:before{
      font-family: "Material Design Icons";
    }
  }
}