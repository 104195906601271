/*
*
* Responsive unit
* --------------------------------------------------
*/


@include unit-responsive($medias, 10px, 30px);

// Unit Spacing
//

.unit-spacing-xs {
  @include unit-spacing($medias, 7px, 20px);
}

html.mac-os,
html.tablet,
html.mobile{
  .page .unit-panel {
    white-space: normal;
  }
}

.page .unit-panel{
  text-align: center;
  background-color: #e9eaf2;
  letter-spacing: 0.025em;
  font-family: $font-family-sec;
  white-space: pre-wrap;

  @media (min-width: $screen-xs-min) {
    text-align: left;
    font-size: 18px;
  }

  a{
    color: $gray-base;
  }

  a:hover{
    color: $secondary;
  }

  .unit__body{
    @media (max-width: $screen-xs-min - 1) {
      padding: 12px;
    }
  }

  .unit__left{
    .icon,
    &{
      @media (max-width: $screen-xs-min - 1) {
        width: 100%;
      }
    }
  }


  &-sm{
    @media (min-width: $screen-xs-min) {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.1em;
    }
  }
}

html.ie-11{
  .page .unit-panel{
    white-space: normal;
  }
}