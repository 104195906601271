/*
*
* Contexts
* --------------------------------------------------
*/


%context-dark {
  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, & {
    color: $white;
  }
}

%context-light {
  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, &{
    color: $gray-base;
  }
}


.context-dark {
  @extend %context-dark;

  .box-icon-range .cell-md-4 + .cell-md-4:after{
    background-color: #595d65;
  }

  .form-label,
  .form-input,
  .form-validation{
    color: $white;
  }
}

.context-light {
  @extend %context-light;
}