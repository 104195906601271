/*
*
* Trunk version 1.2.2
*
*/

/**
* Template Style
*
* [Table of contents]
*   1. Custom styles
*     1.1 Main Styles
*     1.2 Typography
*     1.3 Backgrounds
*     1.4 Colors
*     1.5 Main layout
*
*   2. Components
*     2.1 Icons
*     2.2 Buttons
*     2.3 Forms
*     2.4 Tables
*     2.5 Lists
*     2.6 Post
*     2.7 Thumbnail
*     2.8 Tooltip
*     2.9 Snackbars
*     2.10 Navigation
*     2.11 Preloader
*     2.12 Breadcrumbs
*     2.13 Panel custom
*     2.14 Pagination custom
*
*   3. Helpers
*     3.1 Text-alignment
*     3.2 Text-styling
*     3.3 Visibility responsive
*     3.4 Groups
*     3.5 Context Styling
*     3.6 Sections
*     3.7 Offsets
*
*   4. Modules
*     4.1 Flex grid
*     4.2 Unit-responsive
*
*   5 Plugins
*     5.1 Animate
*     5.2 Isotope
*     5.3 Owl Carousel
*     5.4 RD Navbar
*     5.5 RD Parallax
*     5.6 RD Google-Map
*     5.7 RD Search
*     5.8 To top
*     5.9 Tabs
*     5.10 Photoswipe
*     5.11 Progress-bars
*     5.12 Counter
*     5.13 jquery-circle-progress
*     5.14 Timecircles
*     5.15 Swiper
*
*   6. Fonts
*     6.1 FontAwesome
*     6.2 MDI
**/


// Import variables
@import "variables-custom";

// Import mixins
@import "mixins";


/*
*
* Main Styles
* ==================================================
*/

// Remove leading spacing of element
html {
  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }
}


// Body

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $text-color;
  background-color: $body-bg;
  letter-spacing: 0.05em;
}


// Links

a {
  color: $link-color;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    outline: none;
  }

  &[href*='callto'],
  &[href*='mailto'] {
    white-space: nowrap;
  }
}

.link-hover{
  color: $link-hover-color;
}

.link-press{
  color: $link-press-color;
}

.page .link-icon{
  text-decoration: none;

  &:hover{
    color: $primary;
  }

  .icon{
    margin-right: 6px;
  }
}

.p a:hover,
.list a:hover{
  text-decoration: underline;
}

.mark,
mark{
  background-color: $gray-dark;
  color: $white;
}


// Images

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}


/*
*
* Typography
* ==================================================
*/

#{headings()} {
  margin-top: 0;
  margin-bottom: 0;
  font-family: $headings-font-family;

  font-weight: $headings-font-weight;
  letter-spacing: 0;
  color: $headings-color;

  a {
    transition: .3s all ease;
  }

  a:hover {
    color: darken($primary, 15%);
  }
}

h1,
.heading-1 {
  font-size: 36px;
  line-height: 1.30;
  font-weight: 700;

  @media (min-width: $screen-sm-min) {
    line-height: $font-lh-h1;
    font-size: $font-size-h1;
  }
}

h2,
.heading-2 {
  font-size: 26px;
  line-height: 1.35;

  @media (min-width: $screen-sm-min) {
    line-height: $font-lh-h2;
    font-size: $font-size-h2;
  }
}

h3,
.heading-3 {
  font-size: 24px;
  line-height: 1.35;
  font-weight: 600;

  @media (min-width: $screen-sm-min) {
    line-height: initial;
    font-size: $font-size-h3;
  }
}

h4,
.heading-4 {
  line-height: $font-lh-h4;
  font-size: $font-size-h4;
}

h5,
.heading-5 {
  line-height: $font-lh-h5;
  font-size: $font-size-h5;
  letter-spacing: .02em;
}

h6,
.heading-6 {
  line-height: $font-lh-h6;
  font-size: $font-size-h6;
}

.heading-group{
  * + *{
    margin-top: 10px;
  }
}

.heading-hr{
  padding-bottom: 9px;
  border-bottom: 1px solid #e1e1e1;
}

// Big text
.big {
  line-height: 1.388;

  @media (min-width: 768px) {
    font-size: $font-size-large;
  }
}


// Small text
small,
.small {
  display: block;
  font-size: $font-size-small;
}

// Hr text

.text-hr-wrapper{
  overflow: hidden;
}

.text-hr{
  position: relative;
  display: inline-block;
  font-size: 12px;
  color: $gray-light;
  text-transform: uppercase;

  &:before,
  &:after{
    content: '';
    position: absolute;
    top: 50%;
    height: 1px;
    width: 100vw;
    background-color: #e1e1e1;
  }

  &:before{
    right: calc(100% + 20px);
  }

  &:after{
    left: calc(100% + 20px);
  }

  @media (min-width: 768px) {
    &:before{
      right: calc(100% + 80px);

    }

    &:after{
      left: calc(100% + 80px);
    }
  }
}


// Divider
// -------------------------

hr{
  margin-top: 0;
  margin-bottom: 0;
  border-top: 1px solid #f5f5f5;
}

.divider {
  margin-top: 0;
  margin-bottom: 0;
  border: none;

  height: 2px;
  width: 60px;
  background-color: $gray-base;
}

.divider-right-lg{
  position: relative;

  &:after{
    @media (min-width: $screen-lg-min) {
      content: '';
    }

    position: absolute;
    top: 5px;
    bottom: 70px;
    right: -44px;
    width: 1px;
    background-color: #ccc;
  }
}

.divider-text{
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #e1e1e1;
  color: #9b9b9b;
}


/*
*
* Backgrounds
* ==================================================
*/
// bg-behaviour() off padding top if two section with same bg that are beside

.bg-white {
  @include bg-behaviour($white);
}

.bg-gray-darker {
  @include bg-behaviour($gray-darker);
}

.page .bg-wans {
  @include bg-behaviour(#f3f4f9);
}

.bg-primary {
  @include bg-behaviour($primary);

  // Change color of primary button on primary bg
  .btn-primary {
    @include btn-variant($primary, $white, $white, $primary, darken($white, 5%), darken($white, 5%));
  }
}

.bg-gradient {
  background: $gradient;
}

.bg-image-01{
  background: url("../images/bg-03.jpg");
  background-size: cover;
  @extend %context-dark;
}

.bg-image-02{
  background: url("../images/bg-04.jpg");
  background-size: cover;
  @extend %context-dark;
}

.bg-image {
  background-size: cover;
  background-position: center center;
}

html.desktop {
  .bg-fixed {
    @media (min-width: $screen-md-min) {
      background-attachment: fixed;
    }
  }
}


/*
*
* Colors
* ==================================================
*/

.page {
  @include text-variant('.text-gray-base', $gray-base, $secondary);
  @include text-variant('.text-gray-darker', $gray-darker, $secondary);
  @include text-variant('.text-gray-dark', $gray-dark, $secondary);
  @include text-variant('.text-gray', $gray, $secondary);
  @include text-variant('.text-gray-light', $gray-light, $secondary);
  @include text-variant('.text-gray-lighter', $gray-lighter, $secondary);
  @include text-variant('.text-primary', $secondary, darken($secondary, 10%));
  @include text-variant('.text-primary-dr', $primary-dr, darken($primary-dr, 10%));
  @include text-variant('.text-white', $white, darken($white, 10%));
  @include text-variant('.text-snow', $snow, darken($snow, 10%));
  @include text-variant('.text-athens', #e5e7f0, darken($snow, 10%));
}


/*
*
* Main layout
* ==================================================
*/

// Page
//

.page {
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 100vh!important;
}

.page-fullscreen{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url('../images/bg.png');
    
    background-size: cover;
  }
  
  > *{
    width: 100%;
    position: relative;
  }
}

html.mobile .page-promo .thumbnail-type-4 .caption h4,
html.tablet .page-promo .thumbnail-type-4 .caption h4{
  color: $white;
}

.page-promo{
  background-color: #283034;

  .thumbnail-type-4{
    .caption{
      padding-left: 38px;

      &:before{
        width: 28px;
        background-color: $primary;
      }

      h4{
        color: #fff;
      }
    }

    &:hover{
      .caption{
        h4{
          color: $primary;
        }
      }
    }
  }

  .shell-fluid-1920{
    max-width: 1920px;

    @media (min-width: $screen-lg-min) {
      padding-right: 0;
      padding-left: 0;

      > .range{
        margin-left: 0;
        margin-right: 0;

        > [class*="cell-"]{
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  .cell-inset{
    @media (min-width: $screen-lg-min) {
      padding-left: 4.8vw;
    }

    @media (min-width: 1920px) {
      padding-left: 90px;
    }
  }

  .button{
    margin-top: 26px;
  }

  .border-double{
    margin-top: 20px;
    padding-left: 14%;

    @media (min-width: $screen-xs-min) {
      padding-left: 20%;
    }

    .border-inner{
      position: relative;

      &:before,
      &:after{
        position: absolute;
        top: 0;
        left: -8%;
        display: inline-block;
        content: '';
        width: 1px;
        background-color: #6f7477;
      }

      &:before{
        left: -12%;
        top: -20px;
        height: 65px;
        animation: float 5s linear 1s infinite alternate;
      }

      &:after{
        height: 112px;
        animation: float 5s linear 6s infinite alternate;
      }
    }
  }

  .border-left-wide{
    position: relative;
    display: inline-block;
    letter-spacing: 0;

    &,
    a{
      color: #4f585d;
    }

    &:before{
      content: '';
      position: absolute;
      right: calc(100% + 30px);
      top: 50%;
      height: 1px;
      width: 100vw;
      background-color: #3e4549;
    }

    &:after{
      content: '';
      position: absolute;
      right: calc(100% + 30px);
      height: 16px;
      width: 3px;
      top: calc(50% - 8px);
      background-color: #3e4549;
    }
  }

  h1{
    position: relative;
    padding-left: 8%;
    padding-top: 14px;
    padding-right: 2vw;
    padding-bottom: 14px;
    font-size: 25px;
    line-height: 1.45;
    font-weight: 300;
    letter-spacing: .025em;

    @media (min-width: $screen-sm-min) {
      font-size: 2.5vw;
    }

    @media (min-width: 1920px) {
      font-size: 48px;
    }

    &:before,
    &:after{
      position: absolute;
      content: '';
      border-style: solid;
      border-color: $primary;
      border-width: 0;
      width: 37px;
      height: 41px;
    }

    &:before{
      left: 0;
      top: 0;
      border-top-width: 4px;
      border-left-width: 4px;
    }

    &:after{
      bottom: 0;
      right: 0;
      border-bottom-width: 4px;
      border-right-width: 4px;

      @media (min-width: $screen-sm-min) {
        right: -10px;
      }

      @media (min-width: 1500px) {
        right: 0;
      }
    }
  }

  h4{
    letter-spacing: .025em;
    font-weight: 300;
  }

  .rd-navbar,
  .page-footer,
  .page-header{
    background: transparent;
  }
}

// Page header
//

.page-header {
  //Kill bootstrap style
  padding: 0;
  margin: 0;
  border-bottom: none;
  position: relative;
  z-index: 2;

  background-color: $gray-darker;
}

html.mac-os{
  .page-header{
    will-change: transform;
  }
}

.page-header-minimal{
  padding-top: 40px;

  @media (min-width: $screen-md-min) {
    padding-top: 100px;
    padding-bottom: 30px;
  }
}

.page-justify{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

// Page footer
//

.page-footer {
  padding-top: 44px;
  padding-bottom: 44px;
  background-color: $gray-darker;

  &,
  a{
    color: $white;
  }

  a{
    text-decoration: underline;

    &:hover{
      text-decoration: none;
    }
  }

  &-minimal{
    padding-bottom: 45px;
    padding-top: 15px;

    a{
      color: $white;

      &:hover{
        text-decoration: underline;
      }
    }
  }
}


// Components & modules & plugins & helpers & fonts
//

@import "includes";
@import "components/_layout-panel";