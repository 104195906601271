/*
*
* Thumbnails
* --------------------------------------------------
*/


.thumbnail-type-1{
  display: block;
  overflow: hidden;
  position: relative;

  background-color: #212121;

  img{
    min-width: 100%;
    transition: .45s ease-in-out;
  }

  h3{
    line-height: 1.35;
  }

  .thumbnail-caption{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px 15px;

    color: $white;
    transition: .45s ease-in-out;
    opacity: 0;

    h3{
      margin-top: 6px;
    }

    p{
      margin: 0;
      font-size: 16px;
      font-family: $font-family-sec;
    }
  }

  .thumbnail-caption__footer{
    margin-top: 6px;
    letter-spacing: .1em;

    .icon{
      display: inline-block;
      margin-right: 4px;
    }
  }

  &:hover{
    img{
      opacity: .42;
    }

    .thumbnail-caption{
      opacity: 1;
    }
  }
}

html.mobile,
html.tablet{
  .thumbnail-type-1 {
    img{
      opacity: .42;
    }

    .thumbnail-caption{
      opacity: 1;
    }
  }
}


.grid__item.grid__item--current{
  & + .caption{
    opacity: 0;
    transform: translateY(20px);
  }
}

.thumbnail-type-2 {

  .caption-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .caption{
    padding: 15px 22px;
    background: $gray-darker;
    color: $white;
    font-family: $font-family-sec;
    transition: .3s ease-in-out .06s;

    &-title{
      width: 100%;
      padding-right: 36px;
      text-align: left;
      font-size: 16px;
      letter-spacing: 0.025em;
      font-weight: 600;

      span{
        margin-left: 20px;
        display: inline-block;
        font-size: 12px;
        letter-spacing: .1em;
        color: $primary;
      }
    }

    .list-inline{
      transform: translate(-20px, -50%);
      position: absolute;
      right: 0;
      top: 50%;
      transition: .3s ease-in-out;
      opacity: 0;
      will-change: transform;

      .icon{
        font-size: 14px;
      }

      a{
        color: $white;
        
        &:hover{
          color: $primary;
        }
      }
    }

    &-share{
      display: flex;
      justify-content: center;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: .025em;
      color: $white;
      transition: .3s ease-in-out;

      .icon{
        margin-right: 6px;
      }
    }
  }
  
  .caption-2{
    transition: .3s .3s;
    letter-spacing: 0;
    color: #fff;
    background: #353333;
    padding: 12px 20px;

    @media (min-width: $screen-md-min) {
      padding: 0;
      background-color: transparent;
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(100%);
      padding-left: 24px;
    }
  }

  .thumbnail-img{
    will-change: transform, opacity;
    position: relative;

    img{
      min-width: 100%;
    }

    &:after{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      font-family: "Material Design Icons";
      content: '\f504';
      font-size: 40px;
      color: $white;
      transition: .3s ease-in-out;
      opacity: 0;
      z-index: 101;
    }

    &:before{
      content: '';
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      background-color: #212121;
      opacity: 0;
      transition: .3s ease-in-out;
      z-index: 101;
      will-change: transform, opacity;
    }
  }

  &:hover{
    .thumbnail-img{
      &:after{
        opacity: 1;
      }

      &:before{
        opacity: .58;
      }
    }

    .caption-share{
      opacity: 0;
      transform: translateX(20px);
    }

    .caption{
      .list-inline {
        transform: translate(0px, -50%);
        opacity: 1;
      }
    }
  }
}


[data-instafeed-item] .grid__item {
  position: relative;
  padding-bottom: 100%;

  .thumbnail-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
}

.ie-10,
.ie-11 {
  [data-instafeed-item] .grid__item {
    overflow: hidden;

    .thumbnail-img img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: auto;
      max-width: 135%;
      max-height: 135%;
      min-height: 101%;
      min-width: 101%;
    }
  }
}


html.mobile,
html.tablet{
  *:not(.item__content--inner){
    > .thumbnail-type-2{
      .thumbnail-img{
        &:after{
          opacity: 1;
        }

        &:before{
          opacity: .58;
        }
      }

      .caption-share{
        opacity: 0;
        transform: translateX(20px);
      }

      .caption{
        .list-inline {
          transform: translate(0, -50%);
          opacity: 1;
        }
      }
    }
  }
}

.thumbnail-type-3 {
  position: relative;

  .thumbnail-img{
    &:after{
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background-color: rgba($gray-darker, .4);
      transition: .3s;
      opacity: 0;
    }
  }

  .caption{
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background-color: rgba(#2f3847, .45);
    border: 2px solid white;

    display: flex;
    align-items: center;
    justify-content: center;

    color: $white;

    opacity: 0;
    margin: 0;
    transition: .3s ease-out;
    transform: rotate(2deg);
    
    &-title{
      font-weight: 500;
      font-family: $font-family-sec;
      font-size: 20px;
      line-height: 1.2;

      @media (min-width: $screen-md-min) {
        font-size: 28px;
      }
    }
    
    hr{
      margin-top: 2px;
      margin-bottom: 10px;
      width: 80px;
      opacity: .5;
    }
    
    &-date{
      letter-spacing: .025em;
      color: $primary;
    }
  }

  &:hover{
    .caption{
      opacity: 1;
      transform: rotate(0deg);

      margin: 10px;
      @media (min-width: $screen-sm-min) {
        margin: 30px;
      }
    }

    .thumbnail-img{
      &:after{
        opacity: 1;
      }
    }
  }
}

html.mobile,
html.tablet{
  .thumbnail-type-3{
    .caption{
      opacity: 1;
      transform: rotate(0deg);

      margin: 10px;
      @media (min-width: $screen-sm-min) {
        margin: 30px;
      }
    }

    .thumbnail-img{
      &:after{
        opacity: 1;
      }
    }
  }
}

.owl-carousel-wide{
  .thumbnail-type-4 {
    max-width: none;
  }
}


.thumbnail-type-4{
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;

  .thumbnail-img{
    overflow: hidden;
    position: relative;

    &:after{
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border: 1px solid rgba(255,255,255,.5);
      z-index: 2;
      transition: .3s ease-in-out;
      opacity: 0;
      pointer-events: none;
    }

    .thumbnail-play-icon{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.5);

      display: block;
      z-index: 2;
      height: 75px;
      width: 75px;
      line-height: 75px;
      border-radius: 50%;
      background-color: rgba(#4d5e72, .52);
      color: $primary;
      font-size: 26px;
      transition: .3s ease-in-out;
      opacity: 0;

      &:after{
        content: '\f4f9';
        font-family: "Material Design Icons";
      }
    }
  }


  .caption{
    position: relative;
    margin-top: 10px;
    text-align: left;
    transition: .3s ease-in-out;
    white-space: normal;

    h4{
      transition: .3s ease-in-out;
      font-weight: 600;
      color: #9b9b9b;
    }

    &:before{
      content: '';
      height: 2px;
      position: absolute;
      top: 13px;
      width: 0;
      left: 0;
      margin-top: -1px;
      transition: .3s ease-in-out;
      background-color: $gray-darker;
    }
  }

  &:hover{
    .caption{
      padding-left: 38px;

      h4{
        color: $gray-base;
      }

      &:before{
        width: 28px;
      }
    }

    .thumbnail-img{
      &:after{
        opacity: 1;
        margin: 18px;
      }

      img{
        transform: scale(1.03);
      }

      .thumbnail-play-icon{
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;


        &:hover{
          transform: translate(-50%, -50%) scale(0.9);
        }
      }
    }
  }

  img{
    min-width: 100%;
    transition: .3s ease-in-out;
    z-index: 1;
  }
}

html.mobile,
html.tablet{
  .thumbnail-type-4 {
    .caption{
      padding-left: 38px;

      h4{
        color: $gray-base;
      }

      &:before{
        width: 28px;
      }
    }

    .thumbnail-img{
      &:after{
        opacity: 1;
        margin: 18px;
        .jp-state-plaing & {
          opacity: 0; 
        }
      }

      img{
        transform: scale(1.03);
      }

      &:before{
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
        
      }
      
    }
  }
}


.thumbnail-type-5{
  perspective: 800px;
  perspective-origin: bottom center;

  text-align: left;
  display: inline-block;
  max-width: 320px;

  @media (min-width: $screen-sm-min) {
    max-width: 390px;
  }

  .caption{
    margin-top: 12px;
  }

  .thumbnail-img,
  .stack{
    transform-style: preserve-3d;
    will-change: transform;
    width: 100%;
  }

  .caption-list{
    margin-left: 58px;
    margin-top: 10px;
    padding-left: 18px;
    border-left: 1px solid #d7d7d7;
    padding-top: 7px;
    padding-bottom: 7px;

    li{
      span:first-child{
        display: inline-block;
        text-align: right;
        min-width: 56px;
      }

      span:last-child{
        color: #9b9b9b;
        display: inline-block;
        margin-left: 24px;
      }
    }
  }

  img{
    transition: .3s;
  }

  .grid__item{
    position: relative;
    display: inline-block;
    flex: none;
    transform-style: preserve-3d;
  }
}

html.ie-11,
html.ie-10{
  .thumbnail-type-5 {
    .stack__deco{
      background-color: transparent;
      transform: none!important;
    }

    .stack__figure{
      transform: none!important;
    }

    .stack:hover{
      transform: none!important;
    }

    .stack:hover{
      img{
        opacity: .9;
      }
    }
  }
}


.thumbnail-type-6{
  .caption{
    margin-top: 15px;

    h3{
      color: #9b9b9b;
      font-weight: 500;
    }
  }
}

.stack-coverflow {
  display: inline-block;
  position: relative;
  max-width: 100%;
  perspective: 1600px;

  img {
    transform: translateZ(0) translateX(0) rotateY(0);

    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s;
    outline: 1px solid transparent;
  }

  img:last-child {
    position: relative;
  }

  &:hover{
    img:nth-child(3) {
      transform: translateZ(-300px) translateX(-20%) rotateY(-45deg);
    }

    img:first-child {
      transform: translateZ(-300px) translateX(20%) rotateY(-45deg);
    }

    img:nth-child(2) {
      transform: translateZ(-300px) rotateY(-45deg);
    }

    & + .caption h3{
      color: #151515;
    }
  }
}


html.mac-os{
  .stack-coverflow {
    img {
      opacity: 0;

      &:last-child{
        opacity: 1;
      }
    }

    &:hover{
      img{
        opacity: 1;
      }
    }
  }
}



// Stack
//

.stack {
  position: relative;
  width: 100%;
}

.stack__figure{
  z-index: 1;
  will-change: transform;
}

.stack__deco {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #bdcae0;
  transform-origin: 50% 100%;
}

.stack__deco:first-child {
  opacity: 0.2;
}

.stack__deco:nth-child(2) {
  opacity: 0.4;
}

.stack__deco:nth-child(3) {
  opacity: 0.6;
}

.stack__deco:nth-child(4) {
  opacity: 0.8;
}

// Thumb
//

.thumb{
  position: relative;
  display: inline-block;

  // Bg overlay
  &:before{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: rgba($primary, .4);
    z-index: 1;
    content: '';
    will-change: opacity;
  }

  // Icon
  &:after{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: $white;
    font-family: FontAwesome;
    font-size: 34px;

    content: "\f065";
    z-index: 1;
    will-change: transform, opacity;
  }

  &:before,
  &:after{
    transition: .3s ease;
    opacity: 0;
  }

  &:hover{
    &:before,
    &:after{
      opacity: 1;
    }
  }
}


.js .grid::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background: #252323;
  background-size: 60px auto;
  transition: opacity 0.3s;
}

.js .grid--loaded::after {
  opacity: 0;
}

.grid__item--current {
  opacity: 0 !important;
}

.img-wrap {
  display: block;
}

.img-wrap:focus,
.img-wrap:hover {
  outline: none;
}

.img-wrap img {
  display: block;
  max-width: 100%;
}

.preview {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  pointer-events: none;

  @media (max-width: 768px) {
    display: block;
    align-content: initial;
    align-items: initial;
    width: 100%;
    overflow: scroll;
    flex-direction: column-reverse;
    max-height: 100%;
  }
}

.preview::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: #fff;
  transition: opacity 0.6s;
}

.preview{
  @media (max-width: 768px) {
    .original{
      visibility: hidden;
    }
  }
}

.preview--open {
  pointer-events: auto;

  @media (max-width: 768px) {
    .original{
      visibility: visible;
    }

    .clone{
      visibility: hidden;
    }
  }

  &:before {
    opacity: 1;
  }
}

.clone {
  position: fixed;
  z-index: 110;
  transition: transform 0.5s;
  object-fit: contain;
}

.original {
  position: relative;
  z-index: 120;
  display: block;
  object-fit: contain;
  transition: opacity 0.2s;

  @media (max-width: 768px) {
    max-width: 100% !important;
    max-height: none !important;
  }
}

.preview--open .animate {
  /* open */
  transition: transform 0.6s, opacity 0.2s;
}

.animate {
  /* close */
  transition: transform 0.3s, opacity 0.2s;
}

.description--grid {
  display: none;
}

.description--preview {
  position: absolute;
  z-index: 140;
  width: 100%;
  left: 100%;
  top: 0;
  height: 100%;
  padding: 0 30px;
  opacity: 0;
  transition: opacity 1s, transform 1s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transform: translate3d(0, 30px, 0);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 768px) {
    display: block;
    position: static;
    flex: none;
    height: auto;
    padding: 30px 15px 40px;

    transition: .4s ease-in-out 1.2s;
  }
}

.preview--open .description--preview {
  opacity: 1;
  transition-delay: 0.2s;
  transform: translate3d(0, 0, 0);
}

.description--preview{
  color: #9b9b9b;

  h3 {
    color: $gray-base;
    font-weight: 500;
  }

  p {
    margin: 0;

    @media (min-width: $screen-md-min) {
      max-width: 80%;
    }

    em {
      color: #5D5D5D;
      display: block;
      padding: 0.4em 0 0 0;
    }
  }

  .description-share{
    margin-top: 10px;
    letter-spacing: .0em;

    @media (min-width: $screen-md-min) {
      margin-top: 55px;
    }

    .list-inline{
      display: inline-block;
      margin-left: 5px;

      @media (min-width: $screen-md-min) {
        margin-left: 15px;
      }
    }
  }

  .description-date{
    margin-top: 0;
    font-size: 18px;
    letter-spacing: .025em;
    color: #3f73b4;
    font-family: $font-family-sec;
  }

  hr{
    max-width: 115px;
    margin-top: 24px;
    margin-bottom: 20px;
    border-color: #e1e1e1;
    width: 100%;
    margin-left: 0;
  }
}


/* Details */

.details {
  margin-top: 25px;
  max-width: 100%;
  /* IE 10-11 bug flexbox */

  ul {
    line-height: 1;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      display: inline-block;
      margin: 0 1.3em 0 0;
      padding: 0.15em 0;
      white-space: nowrap;
      opacity: 0;
      transition: transform 1s, opacity 1s;
      transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
      transform: translate3d(0, 20px, 0);
      color: #151515;
    }
  }
}

.preview--open .details ul li {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.preview--open .details ul li:nth-child(1) {
  transition-delay: 0.2s;
}

.preview--open .details ul li:nth-child(2) {
  transition-delay: 0.3s;
}

.preview--open .details ul li:nth-child(3) {
  transition-delay: 0.4s;
}

.preview--open .details ul li:nth-child(4) {
  transition-delay: 0.5s;
}

.preview--open .details ul li:nth-child(5) {
  transition-delay: 0.6s;
}

.details .icon {
  margin-right: 5px;
  color: #3f73b4;
}


/* Close button */

.action {
  font-size: 30px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  vertical-align: top;
  color: $gray-base;
  border: none;
  background: none;
  font-style: normal;
}

.action:hover,
.action:focus {
  color: $secondary;
  outline: none;
}

.action--close {
  position: fixed;
  z-index: 150;
  top: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  transform: scale3d(0.6, 0.6, 1);

  height: 50px;
  width: 50px;
}

.preview--image-loaded .action--close {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.text-hidden {
  position: absolute;
  display: block;
  overflow: hidden;
  width: 0;
  height: 0;
  color: transparent;
}

@media screen and (max-width: 991px) {
  .description--preview p,
  .details {
    //display: none;
  }
}



/**
*  Thumbnail InstaFeedl
*/

.thumbnail-instafeed {
  position: relative;
  padding-bottom: 100%;


  a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  .caption {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: rgba($secondary, .55);
    transition: .3s ease-out all;
    pointer-events: none;
    overflow: hidden;
    will-change: transfrom;
    text-align: center;

    .list-inline {
      margin-left: -10px;
      margin-right: -10px;
      > li {
        padding-left: 10px;
        padding-right: 10px;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .icon {
      font-size: 14px;
      margin-right: 5px;
    }
  }
}

.ie-10,
.ie-11 {
  .thumbnail-instafeed {
    overflow: hidden;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: auto;
      max-width: 135%;
      max-height: 135%;
      min-height: 101%;
      min-width: 101%;
    }
  }
}

@media (min-width: $screen-lg-min) {
  .thumbnail-instafeed {
    .caption {
      .list-inline {
        margin-left: -25px;
        margin-right: -25px;
        > li {
          padding-left: 25px;
          padding-right: 25px;
        }
      }
    }
  }
}

/**
*  Thumbnail InstaFeedl Minimal
*/
.thumbnail-instafeed-minimal {
  .caption {
    .list-inline {
      > li {
        display: block;
      }
      > li + li { margin-top: 10px; }
    }
  }
}

.desktop {
  @media (min-width: $screen-md-min) {
    .thumbnail-instafeed {
      .caption {
        background-color: rgba($secondary, .8);
      }
      .caption,
      .list-inline > li {
        visibility: hidden;
        opacity: 0;
      }
      .list-inline > li {
        transform: scale3d(0, 0, 0);
        will-change: transfrom;
      }
      &:hover {
        .caption,
        .list-inline > li {
          visibility: visible;
          opacity: 1;
        }
        .list-inline > li {
          transform: scale3d(1, 1, 1);
          transition: 480ms cubic-bezier(0.18, 0.89, 0.32, 1.28) transform, 150ms cubic-bezier(0.18, 0.89, 0.32, 1.28) opacity;
          transition-delay: .15s;
        }
      }
    }
  }
}

* + .instafeed { margin-top: 30px; }
.range.instafeed + .button { margin-top: 30px;}