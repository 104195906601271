/*
*
* Box
* --------------------------------------------------
*/

.box-icon{
  position: relative;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;

  padding: 35px 34px;
  color: #5a6270;
  background-color: #404754;
  transition: .3s;

  @media (min-width: 1200px) {
    margin-right: 100px;
  }

  #{headings()}{
    color: $white;
  }

  h5{
    font-weight: 500;
    letter-spacing: 0;
  }

  p{
    margin-top: 0;
  }

  .icon{
    position: absolute;
    right: 20px;
    top: -12px;
    text-align: center;
    width: 90px;
    height: 90px;
    line-height: 90px;
    font-size: 60px;
    color: $primary;
    z-index: 1;

    transition: .3s cubic-bezier(0.65, 0.05, 0.36, 1);

    &:after{
      display: inline-block;
      transition: .3s cubic-bezier(0.65, 0.05, 0.36, 1);
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      background-color: #4f5664;
    }

  }

  &__body{
    &:before{
      content: '';
      display: inline-block;
      width: 75px;
      height: 75px;
      margin-left: 24px;
      margin-bottom: 8px;
      border-top: 1px solid #666c76;
      border-left: 1px solid #666c76;
      transition: .3s cubic-bezier(0.65, 0.05, 0.36, 1);
    }
  }

  &:hover{
    box-shadow: 0 4px 29px 0 rgba(0,0,0,.19);

    .icon{
      top: 20px;
    }

    .icon:after{
      //transform: rotate(45deg);
    }
  }
}

.box-icon-range{
  .cell-md-4 + .cell-md-4,
  .owl-item + .owl-item{
    position: relative;

      &:after{
        @media (min-width: 1200px) {
          content: '';
        }
        position: absolute;
        left: -67px;
        top: 50%;
        height: 1px;
        width: 33px;
        background-color: $gray-light;
      }
  }
}

.page .box-icon-list{
  .box-icon-counter{
    width: auto;
    display: block;
    color: #4f585d;
    content: counter(li, decimal-leading-zero);
    counter-increment: li;

    line-height: 1.4;
    letter-spacing: 0.025em;
    font-weight: 300;
    font-family: $font-family-sec;
    font-size: 24px;
    text-align: left;

    @media (min-width: $screen-sm-min) {
      padding-left: 14%;
      font-size: 3vw;
    }

    @media (min-width: 1920px) {
      font-size: 70px;
    }
  }

  .box-icon{
    margin-left: 0;
    padding: 13% 12%;
    max-width: 285px;
    background-color: #343d42;

    @media (min-width: $screen-md-min) {
      margin-right: 0;
    }

    .icon{
      font-size: 50px;
    }

    h4{
      font-weight: 500;
      letter-spacing: 0.025em;
    }
  }

  &.owl-carousel{
    .owl-stage{
      display: flex;
      align-items: center;
    }

    .owl-dots{
      @media (min-width: $screen-md-min) {
        display: none !important;
      }
    }
  }

  .box-icon .icon:after{
    background-color: #3e474c;
  }

  .cell-md-4 + .cell-md-4:after,
  .owl-item + .owl-item:after{
    left: -78px;
    width: 48px;
    top: 64%;
    content: none;
    background-color: #555d61;

    @media (min-width: 1600px) {
      content: '';
    }
  }
}