/*
*
* Progress Bars
* --------------------------------------------------
*/



// Linear Progress Bars
// --------------------------------------------------

.progress-linear {
  position: relative;
  text-align: left;

  .progress-header{
    @include display-flex();
    @include align-items(center);
    @include justify-content(space-between);
    margin-bottom: 5px;

    font-size: 16px;
    font-weight: 600;
    color: #151515;
    font-family: $font-family-sec;
    letter-spacing: 0;
  }

  .progress-bar-linear-wrap, .progress-bar-linear {
    height: 100%;
  }

  .progress-bar-linear-wrap {
    background: #e5e7f0;
    height: 4px;
  }

  .progress-bar-linear {
    position: relative;
    background-color: #809be9;
    width: 0;
    transition: .5s all ease-in-out;

    &:after{
      content: '';
      position: absolute;
      right: 0;
      top: -4px;
      bottom: -4px;
      width: 5px;
      background-color: #809be9;
    }
  }

  .progress-value{
    display: none;
  }
}

.progress-linear-danger{
  .progress-bar-linear {
    background-color: #ef5b7a;

    &:after{
      background-color: #ef5b7a;
    }
  }
}

.progress-linear-success{
  .progress-bar-linear {
    background-color: #70d1b7;

    &:after{
      background-color: #70d1b7;
    }
  }
}

.progress-linear  + .progress-linear {
  margin-top: 38px;
}
