/*
*
* Instagram Plugin Style
* --------------------------------------------------
*/


/**
* Profile
*/
.instafeed-profile {
  background: rgba(49, 127, 207, 0.05);
  padding: 40px 10px;
  max-width: 370px;
  margin: 0 auto;
  box-shadow: 0 9px 25px -4px rgba(176, 181, 185, 0.43);
  text-align: center;
}

.instafeed-profile-picture {

  > img {
    box-shadow: 0 4px 22px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    width: 150px;
    height: 150px;
  }
}

[data-created_time]{
  text-transform: capitalize;
}

.instafeed-profile-username {
  position: relative;
  text-transform: capitalize;
  color: $secondary;

  > * {
    font-size: 19px;
    color: inherit;
    text-transform: inherit;
  }

  &:after {
    margin-top: 25px;
    display: inline-block;
    content: '';
    width: 60px;
    border-bottom: 1px solid #2f353f;
  }

}

.instafeed-profile-meta {
  font-size: 16px;

  .number{
    padding-right: 5px;
  }

  > .half + .half {
    margin-top: 6px;
  }

}

.instafeed-profile-button {

  .button {
    min-width: 112px;
  }

}

* + .instafeed-profile-username { margin-top: 12px; }

* + .instafeed-profile-meta { margin-top: 20px; }

* + .instafeed-profile-button { margin-top: 30px; }
