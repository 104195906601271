/*
*
* Stack
* --------------------------------------------------
*/

.stack-section {
  position: relative;
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}


/* Hero image */
.hero {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
}

.hero > div {
  background: $violet;
  backface-visibility: hidden;
}

.hero__back--static,
.hero__front {
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.6,0,0.4,1);
  transform-origin: 50% 60px;

  @media (min-width: $screen-md-min) {
    transform-origin: 50% 120px;
  }
}

.hero__back {
  position: absolute;
  width: 100%;
  height: 100%;
}

.hero__back--mover {
  opacity: 0.7;
  transition: transform 0.4s, opacity 0s 0.5s;
}

.move-items .hero__back--mover {
  opacity: 0;
  transition-delay: 0s;
}

.hero__front {
  position: absolute;
  top: 0;
  left: 50%;
  margin: 0 0 0 -392px;
  width: 782px;
  height: 782px;
  border-radius: 50%;
  opacity: 0;
  background-position: top !important;
  display: none;

  @media (min-width: $screen-md-min) {
    display: block;
  }
}

.move-items .hero__back--static {
  opacity: 0;
  transform: scale3d(0.15,0.15,1);
}

.move-items .hero__front {
  opacity: 1;
  transition-duration: 0.5s;
  transform: scale3d(0.15,0.15,1);
  margin: 0 0 0 -382px;
}

html.mac-os{
  .move-items .hero__front {
    margin: 0 0 0 -392px;
  }
}

.hero__focus {
  margin-left: -78px;
  left: 50%;
  position: absolute;
  width: 5px;
  height: 5px;
  background: rgba(255,66,66,0.7);
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(255,66,66,0.9);
  top: 209px;
  display: none;
}


/* Stack slider */
.stack-slider {
  position: absolute;
  height: 25vh;
  width: 100vw;
  top: 0;
  opacity: 0;
  user-select: none;
  transform: translate3d(0, 75vh, 0);
  transition: transform 0.5s, opacity 0.5s;
  transition-timing-function: cubic-bezier(0.6,0,0.4,1);
}

.view-init .stack-slider {
  opacity: 1;
}

.move-items .stack-slider {
  transform: translate3d(0,0,0);
}

.view-full .flickity-viewport {
  overflow: visible;
}


.stacks-wrapper {
  outline: none;
  height: 100%;
}

.stack-slider .stack {
  width: 45%;
  min-width: 270px;
  height: 100%;
  text-align: center;
  //overflow: hidden;
}


//html:not(.mobile),
//html:not(.tablet){
//  body:not(.view-full){
    .stack{
      .stack-title{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }
  //}
//}


html.mac-os{
  body.item-clickable:not(.view-full) {
    overflow: hidden;
  }
}

.stack:not(.is-selected){
  .thumbnail-type-2{
    .caption-2 {
      opacity: 0;
    }
  }
}

body:not(.view-full-instantly){
  .thumbnail-type-2{
    .caption-2{
      transition: .3s .0s;
      opacity: 0;
      transform: translateX(0);
    }
  }
}


body:not(.view-full){
  .stack.is-selected {
    .stack-title:hover{
      @media (min-width: $screen-lg-min) {
        transform: translateY(-20px);
      }

      .icon-cursor{
        &:before{
          transform: rotate(-45deg);
          transform-origin: top right;
        }

        &:after{
          transform: rotate(45deg);
          transform-origin: top left;
        }
      }
    }
  }

  .item{
    .thumbnail-type-2 .thumbnail-img{
      background-color: $violet;
    }

    
  }

  .stack{
    .stack-title {
      height: 100%;
      z-index: 1;

      &:before{
        //content: '';
        position: absolute;
        top: 0;
        bottom: -200px;
        left: -400vw;
        right: -400vw;
        z-index: 1;
        cursor: pointer;
      }
    }
  }

  .item__content--inner img{
    opacity: 1;
  }
}

html.mobile,
html.tablet{

  .hero__back{
    video{
      display: none;
    }
  }

  .stack-slider .stack-title{
    &:before{
      content: none;
    }

    @media (min-width: $screen-lg-min) {
      transform: translateY(-20px);
    }

    .icon-cursor{
      &:before{
        transform: rotate(-45deg);
        transform-origin: top right;
      }

      &:after{
        transform: rotate(45deg);
        transform-origin: top left;
      }
    }
  }
}


body{
  &.view-init {
    overflow-y: scroll;

    .stack-slider{
      .thumbnail-type-2{
        pointer-events: none;
      }
    }
  }

  &.view-full {
    .stack.is-selected {
      .thumbnail-type-2{
        pointer-events: all;
      }
    }
  }
}

.stack.is-selected {
  height: auto;
}

body.view-full-instantly{
  .stack.is-selected {
    .stack-title{
      transition: .25s ease .25s;
      padding: 90px 40px 55px;

      @media (min-width: $screen-md-min) {
        padding: 230px 40px 55px;
      }

      @media (min-width: $screen-lg-min) {
        padding: 240px 40px 55px;
      }

      .icon-cursor{
        &:before{
          transform: rotate(45deg) translateX(8px);
          transform-origin: bottom left;
        }

        &:after{
          transform: rotate(-45deg) translateX(-8px);
          transform-origin: bottom right;
        }
      }
    }
  }
}

.stack-slider{
  .thumbnail-type-2{
    .caption{
      padding-left: 0;
      padding-right: 0;
      background-color: transparent;
      //border-bottom: 1px solid #555962;
    }

    .thumbnail-img{
      display: block;
    }
    
    .caption-share{
      color: $white;
    }
  }
}

.stack.stack-prev,
.stack.stack-next {
  transition: transform 0.5s, opacity 0.5s;
  transition-timing-function: cubic-bezier(0.6,0,0.4,1);
}

.move-items .stack.stack-prev,
.move-items .stack.stack-next {
  opacity: 0;
}

.move-items .stack.stack-prev {
  transform: translate3d(-70px, 65vh, 0);
}

.move-items .stack.stack-next {
  transform: translate3d(70px, 65vh, 0);
}

.stack-title {
  font-weight: 600;
  padding: 50px 40px 55px;
  text-align: center;
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: .4s cubic-bezier(0.4, 0, 0.2, 1);
  margin: 80px 0 0;

  @media (min-width: $screen-sm-min) {
    margin: 80px 0 5px;
  }

  .icon-cursor{
    display: flex;
    align-items: center;
    justify-content: center;

    &:before,
    &:after{
      display: block;
      content: '';

      width: 22px;
      height: 3px;
      background-color: $white;
      transition: .45s cubic-bezier(0.4, 0, 0.2, 1);

      will-change: transform;
    }
  }

  a {
    display: block;
    position: relative;
    color: #fff;
    width: 100%;
    backface-visibility: hidden;

    span {
      display: block;
      color: $white;
      cursor: pointer;
      backface-visibility: hidden;
    }

    &:after {
      content: attr(data-text);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;
      transform: translate3d(0,100%,0);
    }
  }
}

.is-selected .stack-title{
  .icon-cursor{
    display: flex;
    align-items: center;
    justify-content: center;

    &:before,
    &:after{
      background-color: $white;
    }
  }

  > a span {
    color: #fff;
  }
}

.stack-title > a::after,
.stack-title > a span {
  transition: transform 1s 0.15s, opacity 1s 0.15s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.stack-title > a:hover {
  color: #fff;
}

.move-items .is-selected .stack-title > a::after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.move-items .is-selected .stack-title > a span {
  opacity: 0;
  transform: translate3d(0, -150%, 0);
}

.item {
  padding: 0 5% 7%;
  opacity: 0;
  width: 100%;
  position: relative;
  text-align: left;
  transform: translate3d(0,75px,0);
  transition: transform 0.5s, opacity 0.5s;
  transition-timing-function: cubic-bezier(0.6,0,0.4,1);

  & + .item{
    margin-top: 20px;

    @media (min-width: $screen-md-min) {
      margin-top: 0;
    }
  }
}

.move-items .is-selected .item {
  opacity: 1;
}

.move-items .is-selected .item:first-of-type .item__content::after {
  transform: translate3d(0,0,0) scale3d(0.95,0.95,1);
}

.move-items .is-selected .item:first-of-type .item__content::before {
  transform: translate3d(0,0,0) scale3d(0.9,0.9,1);
}

.item:first-of-type {
  opacity: 1;
  transform: translate3d(0,0,0);
}

.stack-title + .item{
  transition: .5s;
  margin-top: 154px;
}

.move-items .is-selected{
  .stack-title + .item{
    margin-top: 224px;

    @media (min-width: $screen-md-min) {
      margin-top: 354px;
    }
  }
}

.move-items .is-selected .item {
  transition-delay: 0.15s;
  transform: translate3d(0,0,0);
}

.move-items .is-selected .item:first-of-type {
  transition-delay: 0s;
}

.item__content {
  position: relative;
  z-index: 100;
  max-width: 770px;
  margin: 0 auto;

  &--inner{
   

    img{
    }
  }
}

.item__content p {
  font-size: 0.75em;
}

.item:first-of-type .item__content::before,
.item:first-of-type .item__content::after {
  content: '';
  width: 100%;
  height: 20px;
  top: 0;
  left: 0;
  z-index: -1;
  position: absolute;
  transition: transform 0.3s;
  backface-visibility: hidden;
}

.item:first-of-type .item__content::before {
  background: #D39DD2;
  transform: translate3d(0,-20px,0) scale3d(0.9,0.9,1);
}

.item:first-of-type .item__content::after {
  background: #D07499;
  transform: translate3d(0,-10px,0) scale3d(0.95,0.95,1);
}

.item img {
  width: 100%;
  display: block;
  position: relative;
  z-index: 100;
  transition: .45s;
}

.item__title {
  font-size: 1.25em;
  margin: 0;
  padding: 0.75em 0;
  color: #9d9d9d;
}

.item__date {
  font-size: 0.5em;
  vertical-align: middle;
  display: inline-block;
  color: #4F4E4E;
  margin-left: 5px;
}

.item__details {
  text-align: left;
  margin: 0 0 2em;
}

.item__details ul {
  font-family: 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  list-style: none;
  margin: 0;
  padding: 0;
}

.item__details ul li {
  display: block;
  padding: 3px 0;
  color: #9d9d9d;
  white-space: nowrap;
  opacity: 0;
  transform: translate3d(0,20px,0);
  transition: transform 0.5s, opacity 0.5s;
}

.move-items .is-selected .item__details ul li {
  opacity: 1;
  transform: translate3d(0,0,0);
}

.move-items .is-selected .item__details ul li:first-child {
  transition-delay: 0.25s;
}

.move-items .is-selected .item__details ul li:nth-child(2) {
  transition-delay: 0.3s;
}

.move-items .is-selected .item__details ul li:nth-child(3) {
  transition-delay: 0.35s;
}

.move-items .is-selected .item__details ul li:nth-child(4) {
  transition-delay: 0.4s;
}

.move-items .is-selected .item__details ul li:nth-child(5) {
  transition-delay: 0.45s;
}

.item__details ul li:first-child {
  font-weight: bold;
  color: #ef5350;
}


/* Related demos */
.item__content--related {
  padding-bottom: 280px;
  text-align: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.item__content--related > p {
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  padding: 1em 0;
}

.media-item {
  display: block;
  margin: 0 auto;
  max-width: 50%;
  min-width: 250px;
  font-weight: bold;
  padding: 1em;
}

.media-item__img {
  max-width: 250px;
  width: auto;
  opacity: 0.3;
  transition: opacity 0.3s;
}

.media-item:hover .media-item__img,
.media-item:focus .media-item__img {
  opacity: 1;
}

.media-item__title {
  font-size: 1em;
  margin: 0;
  padding: 0.5em;
}

/* Mobile-first media queries */
@media screen and (min-width: 65em) {
  .item__details {
    position: absolute;
    top: 0;
    margin: 0 0 0 1em;
    z-index: 1000;
    left: 100%;
    width: 40%;
  }
}

/* Mobile-specific media queries */
@media screen and (max-width: 800px){
  body #cdawrap,
  body.move-items #cdawrap {
    display: none !important;
  }
  .item__content--related {
    padding-bottom: 0 !important;
  }
}

@media screen and (max-width: 65em) {
  .stack {
    width: 60%;
  }
}

@media screen and (max-width: 45em) {
  .menu__item {
    display: block;
    background: #4F4E4E;
    overflow: hidden;
    height: 4px;
    width: 30px;
    margin: 3px 10px 0 0;
  }
  .menu__item span {
    display: none;
  }
  .codrops-header {
    padding: 0.5em 0;
  }
  .stack.stack-prev .stack-title::after,
  .stack.stack-next .stack-title::after {
    top: 1.85em;
  }
  .codrops-header h1 {
    font-size: 0.85em;
    padding: 0.25em;
  }
}

@media screen and (max-height: 35em) {
  .stack-slider {
    height: 45vh;
    transform: translate3d(0, 55vh, 0);
  }
}

/*! Flickity v1.0.0
http://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

body.view-full{
  .flickity-slider .stack.is-selected{
    .thumbnail-type-2{
      @media (max-width: $screen-lg-min - 1) {
        .thumbnail-img:before{
          opacity: .58;
        }

        .thumbnail-img:after{
          opacity: 1;
        }

        .caption-share{
          opacity: 0;
        }

        .caption .list-inline{
          transform: translate(0px, -50%);
          opacity: 1;
        }
      }
    }
  }

}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  //cursor: move;
  //cursor: -webkit-grab;
  //cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: hsla(0, 0%, 100%, 0.75);
  cursor: pointer;
  /* vertically center */
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.flickity-prev-next-button:hover { background: white; }

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F;
}

.flickity-prev-next-button:active {
  filter: alpha(opacity=60); /* IE8 */
  opacity: 0.6;
}

.flickity-prev-next-button.previous { left: 10px; }
.flickity-prev-next-button.next { right: 10px; }
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button:disabled {
  filter: alpha(opacity=30); /* IE8 */
  opacity: 0.3;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.flickity-prev-next-button .arrow {
  fill: #333;
}

/* color & size if no SVG - IE8 and Android 2.3 */
.flickity-prev-next-button.no-svg {
  color: #333;
  font-size: 26px;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  filter: alpha(opacity=25); /* IE8 */
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  filter: alpha(opacity=100); /* IE8 */
  opacity: 1;
}


.view-init{
  @media (min-width: $screen-md-min) {
    .rd-navbar-wrap{
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      text-align: center;

      .rd-navbar{
        background: none;

        .rd-navbar-brand .brand-slogan{
          border-left-color: $white;
        }
      }
    }
  }
}

// Stack section variations
// =====

body.view-full-instantly .stack-section-single .stack.is-selected .stack-title {
  padding: 140px 40px 55px;
}

.move-items .stack-section-single .is-selected .stack-title + .item{
  margin-top: 254px;
}

.stack-section-single{

  .hero__back.hero__back--mover{
    display: none;
  }

  .hero__front{
    display: none;
  }

  .hero > div{
    transform: none;
  }
}

.stack-section-parallax{
  .hero__back.hero__back--mover{
    display: block;
  }
}

body.view-full{
  .stack-section{
    background: linear-gradient( 135deg, $violet, #FFD29F);
    position: relative; 
    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url('../images/bg.png') no-repeat;
      background-size:  cover;
      opacity: .6;
      background-attachment: fixed;
    }
  }
}