/*
*
* Time Circles
* --------------------------------------------------
*/


/**
 *	This element is created inside your target element
 *	It is used so that your own element will not need to be altered
 **/
.time_circles {
  position: relative;
  width: 100%;
  height: 100%;
}

.DateCountdown{
  margin: 0 auto;
  max-width: 820px;
}

/**
 *	This is all the elements used to house all text used
 * in time circles
 **/
.time_circles > div {
  position: absolute;
  text-align: center;
  top: 50% !important;
  transform: translateY(-63%) !important;
  -webkit-transform: translateY(-63%) !important;

  display: flex;
  flex-direction: column-reverse;
}

@media (min-width: 1200px) {
  #DateCountdown {
    width: 100%;
  }
}

/**
 *	Titles (Days, Hours, etc)
 **/
.time_circles > div > h4 {
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 14px !important;
  font-weight: 400;
  top: 16vw;
  letter-spacing: 0.05em;
  line-height: 1.2 !important;
  opacity: .8;

  @media (min-width: $screen-xs-min) {
    text-transform: uppercase;
  }
}

.time_circles > div > h4 + * {
  margin-top: 0;
}

@media (min-width: 480px) {
  .time_circles > div > h4 {
    top: 75px;
  }
}

@media (min-width: 992px) {
  .time_circles > div > h4 {
    top: 89px;
    margin-top: 4px;
  }
}

/**
 *	Time numbers, ie: 12
 **/
.time_circles > div > span {
  display: block;
  font-size: 30px;
  text-align: center;
  font-weight: 400;
  line-height: 1.2 !important;
}

@media (min-width: 768px) {
  .time_circles > div > span {
    font-size: 36px !important;
  }
}

@media (min-width: 1200px) {
  .time_circles > div > span {
    font-size: 40px !important;
  }
}
