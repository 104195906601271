/*
* Static Layout
*/

.rd-navbar-static {
  display: block;

  .rd-navbar-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-top: 30px;
    padding-bottom: 30px;
  }

  .rd-navbar-aside-right{
    display: flex;
  }

  .rd-navbar-top-panel{
    text-align: left;
  }

  .rd-navbar-nav{
    > li {
      & + li{
        margin-left: $navbar-static-nav-indent;
      }
    }
  }

  &.rd-navbar--is-stuck,
  &.rd-navbar--is-clone{
    .rd-navbar-inner{
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
}

