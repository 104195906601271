/*
*
* Sly
* --------------------------------------------------
*/

.sly{

}

.sly--inner{
	display: flex;
}


.sly-wrapper{
	margin-top: 40px;
}


.sly-item__wrapper{
	flex-shrink: 0;

	width: calc(100vw - 38px);

	@media (min-width: $screen-xs-min) {
		width: 450px;
	}

	@media (min-width: $screen-sm-min) {
		width: 345px;
	}

	@media (min-width: $screen-md-min) {
		width: 293px;
	}

	@media (min-width: $screen-lg-min) {
		width: 23.15vw;
	}

	@media (min-width: 1800px) {
		width: 442px;
	}

	& + .sly-item__wrapper {
		margin-left: 30px;

		@media (min-width: $screen-lg-min) {
			margin-left: 1.6vw;
		}

		@media (min-width: 1800px) {
			margin-left: 30px;
		}
	}
}

/* Scrollbar */
.scrollbar {
	margin-top: 40px;
	margin-bottom: 10px;
	height: 2px;
	background: #ccc;
	line-height: 0;

	max-width: 360px;
	margin-left: auto;
	margin-right: auto;
}
.scrollbar .handle {
	width: 100px;
	height: 100%;
	background: #292a33;
	cursor: pointer;
}
.scrollbar .handle .mousearea {
	position: absolute;
	top: -9px;
	left: 0;
	width: 100%;
	height: 20px;
}