/*
*
* Offsets
* --------------------------------------------------
*/

.page{
  .offset-top-0{
    margin-top: 0;
  }

  .offset-top-10{
    margin-top: 10px;
  }

  .offset-top-30{
    margin-top: 30px;
  }

  .offset-top-50{
    margin-top: 50px;
  }

  .inset-lg-right-70{
    @media (min-width: $screen-lg-min) {
      padding-right: 70px;
    }
  }
}

// Element offset
* + p { margin-top: 15px; }
p + p { margin-top: 22px; }


// Headings
h1 + h2 { margin-top: 32px; }
h2 + h3 { margin-top: 32px; }
h3 + h4 { margin-top: 32px; }
h4 + h5 { margin-top: 40px; }
h5 + h6 { margin-top: 40px; }

// Headings + Paragraph
h1 + p { margin-top: 34px; }
h2 + p { margin-top: 30px; }
h3 + p { margin-top: 23px; }
h4 + p { margin-top: 12px; }
h5 + p { margin-top: 12px; }
h6 + p { margin-top: 12px; }


// Paragraph + Headings
p + h2 { margin-top: 70px; }
p + h3 { margin-top: 60px; }
p + h4 { margin-top: 70px; }
p + h5 { margin-top: 65px; }
p + h6 { margin-top: 70px; }


// Tags + Tags
img + p { margin-top: 15px; }
h3 + img { margin-top: 42px; }


// Tags + Classes
p + .list-marked { margin-top: 10px; }
h3 + .quote { margin-top: 20px; }
h3 + .group-sm { margin-top: 30px; }
p + .unit-panel { margin-top: 40px; }
h1 + .quote--variant-1{ margin-top: 24px; }

// Classes
* + .tabs-custom-wrap{ margin-top: 50px; }
* + .tabs-vertical{ margin-top: 50px; }
* + .rd-mailform { margin-top: 30px; }
* + .group-xl { margin-top: 40px; }
* + .pagination-custom { margin-top: 50px; }
* + .table-custom-wrap { margin-top: 40px; }
.page * + .progress-bar-range {
  margin-top: 40px;

  @media (min-width: 992px) {
    margin-top: 90px;
  }
}

// Classes + Tags
.range + h3 { margin-top: 80px; }
.quote--variant-1 + p { margin-top: 30px; }

// Classes + Classes
.shell + .shell { margin-top: 80px; }
.range + .range { margin-top: 60px; }
.unit-panel + .unit-panel {
  margin-top: 15px;

  @media (min-width: $screen-md-min) {
    margin-top: 40px;
  }
}


// Media offsets

@media (min-width: $screen-sm-min) {

}

@media (min-width: $screen-md-min) {
  .offset-md-top-50{
    margin-top: 50px;
  }
}

@media (min-width: $screen-lg-min) {

}

html {
  // Range spacing
  .range-60 {
    @include responsive-offset(60px);
  }

  .range-40 {
    @include responsive-offset(30px);
  }

  .range-30 {
    @include responsive-offset(30px);
  }

  .range-15 {
    @include responsive-offset(15px);
  }

  @media (min-width: $screen-md-min) {
    .range-md-30 {
      @include responsive-offset-media(30px);
    }
  }
}