/*
*
* ToTop
* --------------------------------------------------
*/

html body .ui-to-top {
  width: 50px;
  height: 50px;
  font-size: 32px;
  line-height: 46px;
  color: #212121;
  background: $primary;
  //border-radius: 50%;
  position: fixed;
  right: 15px;
  bottom: 15px;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  z-index: 20;
  transition: .25s all ease-in-out;
  transform: translateX(90px);
  box-shadow: 0 8px 19px -2px rgba(0, 0, 0, 0.09);

  &:hover {
    color: $white;
    background: darken(#212121, 10%);
    text-decoration: none;
    box-shadow: 0 3px 8px 0px rgba(0, 0, 0, 0.19);
  }

  &:focus:hover{
    color: #fff;
  }

  &:focus{
    color: #212121;
  }

  &.active{
    transform: translateX(0);
  }
}

html.mobile .ui-to-top,
html.tablet .ui-to-top {
  display: none !important;
}

@media (min-width: $screen-xs-min){
  html body .ui-to-top {
    right: 20px;
    bottom: 130px;
  }
}


